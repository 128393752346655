import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class AdminNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <div className="px-3 py-2 bg-dark text-white">
                    <div className="container">
                        <div
                            className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                            <Link aria-current="page" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
                                  to={"/admin/orders/"}>
                                <div className='nav-link text-white'>
                                    <i className="bi d-block mx-auto mb-1 bi-sliders" width="40" height="32"></i>
                                </div>
                                Administration
                            </Link>

                            <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                                <li>
                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin/"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi bi-house" width="24" height="24"></i>
                                        </div>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi bi-speedometer2" width="24"
                                               height="24"></i>
                                        </div>
                                        Tableau de bord
                                    </Link>
                                </li>
                                <li>
                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin/sales/"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi bi-table" width="24"
                                               height="24"></i>
                                        </div>
                                        Commandes
                                    </Link>
                                </li>
                                <li>
                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin/products/"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi bi-grid" width="24"
                                               height="24"></i>
                                        </div>
                                        Produits
                                    </Link>
                                </li>
                                <li>

                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin/users/"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi  bi-person-circle" width="24"
                                               height="24"></i>
                                        </div>
                                        Utilisateurs
                                    </Link>
                                </li>
                                <li>

                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin/lessons/"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi  bi-book" width="24"
                                               height="24"></i>
                                        </div>
                                        Lessons
                                    </Link>
                                </li>
                                <li>
                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin/configurations/"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi  bi-pie-chart-fill" width="24"
                                               height="24"></i>
                                        </div>
                                        Configurations
                                    </Link>
                                </li>
                                <li>
                                    <Link aria-current="page" className="nav-link text-white"
                                          to={"/admin/group_sessions/"}>
                                        <div className='bi d-block mx-auto mb-1'>
                                            <i className="bi  bi-list-task" width="24"
                                               height="24"></i>
                                        </div>
                                        Sessions de groupe
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
