import React, {Component} from "react";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";
import $ from "jquery";
import {Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import logo from '../images/logo_pp.svg'
import UserService from "../services/user.service";
import '../styles/sidebar.scss';
class Menu extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.onClickSidebarCollapse = this.onClickSidebarCollapse.bind(this);

        this.state = {
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: AuthService.getCurrentUser(),
        };
    }

    async componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            let currentUser = (await UserService.getMyProfile(user.id)).data
            this.setState({
                currentUser: currentUser,
                showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                showAdminBoard: user.roles.includes("ROLE_ADMIN"),
            });
        }

        EventBus.on("logout", () => {
            this.logOut();
        });
    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    onClickSidebarCollapse() {

        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    }

    logOut() { 
        AuthService.logout();
        window.location.reload();
        this.setState({
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
        });
    }

    render() {
        const {t} = this.props;
        const {currentUser} = this.state;
        return (
            <>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
                    <div className="container-fluid">
                        <button type="button" id="sidebarCollapse" className="btn "
                                onClick={this.onClickSidebarCollapse}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link to={"/"} className="navbar-brand">
                            <img src={logo} alt="logo" height="55"
                                 className="d-inline-block align-text-top"/>
                        </Link>
                        <Link to={"/"}
                              className="d-flex align-items-center mb-md-0 me-md-auto text-white text-decoration-none">
                            <span className="fs-4">talkeasy</span>
                        </Link>

                        <div className="collapse navbar-collapse div-top-navbar-options" id="navbarSupportedContent">

                            <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                                <li>
                                    <Link to={"/"} className="nav-link px-2 text-white pp-link-underscore-primary">
                                        {t('Accueil')}
                                    </Link>
                                </li>
                              {/*  <li>
                                    <Link to={"/tarif"} className="nav-link px-2 link-dark pp-link-underscore-primary">
                                        {t('Nos formules')}
                                    </Link>
                                </li>*/}
                               {/* <li>
                                    <Link to={"/cpf"} className="nav-link px-2 link-dark pp-link-underscore-primary">
                                        {t('Qu\'est-ce-que le CPF ?')}
                                    </Link>
                                </li>*/}
                                <li>
                                    <Link to={"/intro/individuel"} className="nav-link px-2 text-white pp-link-underscore-primary">
                                        {t("Les cours d'anglais")}
                                    </Link>
                                </li>
                                {/*<li>
                                    <div className={"dropdown"}>
                                        <a href="/"
                                           className="nav-link px-2 text-white pp-link-underscore-primary dropdown-toggle"
                                           id="dropdownUser2" data-bs-toggle="dropdown"
                                           aria-expanded="false">
                                            Nos cours
                                        </a>
                                        <ul className="dropdown-menu text-small shadow"
                                            aria-labelledby="dropdownUser2">
                                            <li>
                                                <Link aria-current="page" className="dropdown-item"
                                                      to={"/intro/individuel"}>
                                                    {t('Cours individuels')}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link aria-current="page" className="dropdown-item"
                                                      to={"/intro/groupe"}>
                                                    {t('Cours en groupe')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>*/}

                               {/* <li>
                                    <div className="dropdown">
                                        <a href="/"
                                           className="nav-link px-2 text-white pp-link-underscore-primary dropdown-toggle"
                                           id="dropdownUser2" data-bs-toggle="dropdown"
                                           aria-expanded="false">
                                            Nos langues
                                        </a>
                                        <ul className="dropdown-menu text-small shadow"
                                            aria-labelledby="dropdownUser2">
                                            <li>
                                                <Link aria-current="page" className="dropdown-item"
                                                      to={"/english"}>
                                                    {t('Anglais')}
                                                </Link>
                                            </li>
                                           <li>
                                                <Link aria-current="page" className="dropdown-item"
                                                      to={"/italian"}>
                                                    {t('Italien')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>*/}
                               {/* <li>
                                    <Link to={"/about-us"} className="nav-link px-2 link-dark pp-link-underscore-primary">
                                        {t('Qui sommes nous')}
                                    </Link>
                                </li>*/}
                                <li>
                                    <Link to={"/contact-us"}
                                          className="nav-link px-2 text-white pp-link-underscore-primary">
                                        {t('Nous contacter')}
                                    </Link>
                                </li>

                                {currentUser ? (
                                    <>
                                        <li>
                                            <div className={"dropdown"}>
                                                <a href="/"
                                                   className="nav-link px-2 link-dark pp-link-underscore-primary dropdown-toggle"
                                                   id="dropdownUser2" data-bs-toggle="dropdown"
                                                   aria-expanded="false">
                                                    <strong>{currentUser.email}</strong>
                                                </a>
                                                <ul className="dropdown-menu text-small shadow"
                                                    aria-labelledby="dropdownUser2">
                                                    <li>
                                                        <Link aria-current="page" className="dropdown-item"
                                                              to={"/profile"}>
                                                            {t('Profile')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link aria-current="page" className="dropdown-item"
                                                              to={"/lessons"}>
                                                            {t('Cours individuels')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link aria-current="page" className="dropdown-item"
                                                              to={"/group"}>
                                                            {t('Cours en groupe')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <hr className="dropdown-divider"/>
                                                    </li>
                                                    <li>
                                                        <Link aria-current="page" className="dropdown-item" to={"/"}
                                                              onClick={this.logOut}>
                                                            {t('Sign out')}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </>

                                ) : (
                                    <div className="buttons-login">
                                 {/*       <Link className="btn btn-outline-primary me-2" to={"/login"}
                                              onClick={this.onClickCloseSidebar}>
                                            {t('Sign in')}
                                        </Link>
                                        <Link className="btn btn-primary" to={"/register"}
                                              onClick={this.onClickCloseSidebar}>
                                            {t('Sign up')}
                                        </Link>*/}
                                    </div>
                                )
                                }
                            </ul>
                        </div>

                    </div>
                </nav>
            </>
        );
    }
}
export default withTranslation()(Menu);
