import React, {Component} from "react";

import {withTranslation} from "react-i18next";
import GroupService from "../../services/group.service";
import UserService from "../../services/user.service";
import {Link} from "react-router-dom";
import Modal from "../modal.component"

class GroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: "true",
            alertSuccess: "",
            alertMessage: "",
            modalName: "Information importante",

            successful: false,
            message: "",
            profile: [],
            lessons: [],
            mylessons: [],


        };
        this.showModal = this.showModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleClickDetails = this.handleClickDetails.bind(this)
    }


    async componentDidMount() {
        let connected = await UserService.isConnected()
        if (!connected) {
            this.props.history.push("/");
        }
        let lessons = (await GroupService.getOpen()).data;
        let mylessons = (await GroupService.getUserGroupSessions()).data;
        let profile = (await UserService.getRefreshMyProfile()).data
        if (lessons !== null) {
            await this.setState({
                lessons: lessons,
                profile: profile,
                mylessons: mylessons,
                showModal: false
            })
        }
    }

    async signIn(id) {
        try {
            await GroupService.signIn(id);
            await this.setState({
                alert: true,
                alertSuccess: "success",
                alertMessage: "Votre inscription a été prise en compte avec succès."
            })
            this.showModal()
        } catch (e) {
            await this.setState({
                alert: true,
                alertSuccess: "danger",
                alertMessage: "Une erreur est survenue"
            })
            this.showModal()
        }

    }

    async signOut(id) {
        try {
            await GroupService.signOut(id);
            await this.setState({
                alert: true,
                alertSuccess: "success",
                alertMessage: "Votre inscription a été annulé avec succès."
            })
            this.showModal()
        } catch (e) {
            await this.setState({
                alert: true,
                alertSuccess: "danger",
                alertMessage: "Une erreur est survenue"
            })
            this.showModal()
        }
    }

    showModal() {
        this.setState({
            showModal: true
        })
    }

    closeModal() {
        this.setState({
            showModal: false
        })
        window.location.reload();
    }

    async handleClickDetails(data) {
        this.setState({
            modalName: "Les détails du cours",
            alert: "false",
            modalContent: this.renderDetails(data)
        })
        this.showModal()
    }
    convertDatetime(datetime) {
        return {
            "date": new Date(datetime).toLocaleDateString(),
            "time": new Date(datetime).toLocaleTimeString()
        }
    }


    renderDetails(data) {
        return (
            <>
                <div className="card-body">
                    <h5 className="card-title">
                        Cours en groupe de {data.max_person_qty}
                    </h5>
                    <p className="card-text">
                        <b>Session
                            du {new Date(data.datetime).toLocaleDateString() + " "
                            + new Date(data.datetime).toLocaleTimeString()}</b>
                    </p>
                    <p className="card-text">
                        Quantité d'heures : {data.lesson_qty + " heures"}
                    </p>

                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date et l'heure</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.lessons.map((lesson, index)=>(
                                <tr>
                                    <th scope="row">{index+1}</th>
                                    <td>{this.convertDatetime(lesson.datetime).date + " " + this.convertDatetime(lesson.datetime).time}</td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                <Modal
                    alertSuccess={this.state.alertSuccess}
                    alertMessage={this.state.alertMessage}
                    modalName={this.state.modalName}
                    alert={this.state.alert}
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    modalContent={this.state.modalContent}

                />
                <div className="py-4 container container-homepage ">
                    <div className="col-md-12">

                        {this.state.mylessons.length > 0 ?
                            <>
                                <div className="card card-container">

                                    <div className="form-signin">
                                        <h3 className="mb-1"> Vos inscriptions : </h3>
                                    </div>
                                    <div className="row justify-content-center">
                                        {
                                            this.state.mylessons.map((data) => (
                                                <div className="col-sm-3 align-content-center" key={"mylsn_" + data.id}>
                                                    <div className="card  text-center">
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                Cours en groupe de {data.max_person_qty}
                                                            </h5>
                                                            <p className="card-text">
                                                                <b>Session
                                                                    du {new Date(data.datetime).toLocaleDateString() + " "
                                                                    + new Date(data.datetime).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</b>
                                                            </p>
                                                            <p className="card-text">
                                                                Quantité d'heures : {data.lesson_qty + " heures"}
                                                            </p>
                                                            <div className="d-grid gap-2">
                                                                <button className="btn btn-primary"
                                                                   onClick={() => this.handleClickDetails(data)}>Détails</button>
                                                                <button className="btn btn-outline-danger"
                                                                   onClick={() => this.signOut(data.id)}>Se
                                                                    désinscrire</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                        }


                        {
                            this.state.lessons.length > 0 ?
                                <>
                                    <div className="form-signin">
                                        <h3 className="mb-1">Votre solde
                                            : {this.state.profile.group_lessons_balance === null ? 0 :
                                                this.state.profile.group_lessons_balance} heures</h3>
                                        {this.state.profile.group_lessons_balance === null ?
                                            <Link to={"/tarif"}
                                                  className="w-100 btn btn-lg btn-primary pp-button-home-level">
                                                Acheter un pack
                                            </Link>
                                            :
                                            null
                                        }
                                    </div>
                                    <hr/>
                                    <div className="card card-container">
                                        <div className="form-signin">
                                            <h3 className="mb-1">Inscrivez-vous : </h3>
                                        </div>

                                        <div className="row justify-content-center">
                                            {
                                                this.state.lessons.map((data) => (
                                                    <div className="col-sm-3" key={"lsn_" + data.id}>
                                                        <div className="card  text-center">
                                                            <div className="card-body">
                                                                <h5 className="card-title">
                                                                    Cours en groupe de {data.max_person_qty}
                                                                </h5>
                                                                <p className="card-text">
                                                                    <b>Session
                                                                        du {new Date(data.datetime).toLocaleDateString() + " "
                                                                        + new Date(data.datetime).toLocaleTimeString()}</b>
                                                                </p>
                                                                <p className="card-text">
                                                                    Quantité d'heures : {data.lesson_qty + " heures"}
                                                                </p>
                                                                <div className="d-grid gap-2">
                                                                    <button className="btn btn-outline-primary"
                                                                       onClick={() => this.handleClickDetails(data)}>Détails</button>
                                                                    <button className="btn btn-primary"
                                                                       onClick={() => this.signIn(data.id)}>S'inscrire</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(GroupList)