import React, {Component} from 'react';
import '../../styles/home.scss';
import {withTranslation} from "react-i18next";
import MetaTags from 'react-meta-tags';

class English extends Component {

    constructor() {
        super();

        this.state = {
            modalAdditionalMessage: ""
        }

    }

    componentDidMount() {
        document.title = 'Cours de langues étrangers particuliers - talkeasy.fr'
    }


    render() {
        return (
            <>
                <MetaTags>
                    <title>Apprentissage de l'Anglais en Ligne - talkeasy.fr</title>
                    <meta name="description" content="Découvrez pourquoi notre méthode d'apprentissage de l'anglais en ligne est la meilleure. Apprenez de manière efficace avec talkeasy.fr." />
                </MetaTags>
                <div className="py-4 container-homepage">
                    <div className="container">
                        <h2>Apprentissage de l'Anglais en Ligne</h2>
                        <p>
                            Chez talkeasy.fr, nous sommes fiers de vous proposer la meilleure méthode d'apprentissage de l'anglais en ligne. Découvrez pourquoi nos cours en ligne sont la solution idéale pour maîtriser cette langue :
                        </p>
                    </div>
                </div>
                <div className="row g-3 text-center div-circles">
                    <div className="card" style={{ width: '18rem' }}>
                        <i className="bi bi-check-circle-fill primary-color" style={{ fontSize: '50px' }}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Méthode d'enseignement interactive et engageante
                            </p>
                        </div>
                    </div>
                    <div className="card" style={{ width: '18rem' }}>
                        <i className="bi bi-bullseye primary-color" style={{ fontSize: '50px' }}></i>
                        <div className="card-body">
                            <p className="card-text">Cours personnalisés selon votre niveau et vos objectifs</p>
                        </div>
                    </div>
                    <div className="card" style={{ width: '18rem' }}>
                        <i className="bi bi-globe primary-color" style={{ fontSize: '50px' }}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Accès à des ressources d'apprentissage en ligne de haute qualité
                            </p>
                        </div>
                    </div>
                </div>
                <div className="py-4 container-homepage">
                    <div className="container">
                        <h2>Pourquoi Nos Cours en Ligne Sont les Meilleurs</h2>
                        <p>
                            Nos cours en ligne d'anglais sont conçus pour vous offrir une expérience d'apprentissage efficace et flexible. Notre méthode interactive et engageante vous permet de progresser rapidement. Nous adaptons nos cours en fonction de votre niveau et de vos objectifs spécifiques, garantissant ainsi une expérience d'apprentissage personnalisée. De plus, vous aurez accès à une vaste gamme de ressources d'apprentissage en ligne de haute qualité pour renforcer vos compétences linguistiques. Rejoignez talkeasy.fr dès aujourd'hui et découvrez pourquoi nous sommes la meilleure option pour l'apprentissage de l'anglais en ligne !
                        </p>
                    </div>
                </div>
            </>
        );
    }


}

export default withTranslation()(English);