import React, { Component } from 'react';
import '../../styles/home.scss';
import { withTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';
import Intro from '../../videos/intro.mp4'
class English extends Component {

    constructor() {
        super();

        this.state = {
            modalAdditionalMessage: ""
        }
    }

    componentDidMount() {
        document.title = 'Cours de langues étrangers particuliers - talkeasy.fr'
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Apprentissage de l'Anglais en Ligne - talkeasy.fr</title>
                    <meta name="description" content="Découvrez pourquoi notre méthode d'apprentissage de l'anglais en ligne est la meilleure. Apprenez de manière efficace avec talkeasy.fr." />
                </MetaTags>
                <br/><br/><br/>
                <div className="video-intro-container">
                    <video width="100%" height="auto" controls autoplay>
                        <source src={Intro} type="video/mp4" />
                        Votre navigateur ne supporte pas la vidéo.
                    </video>
                </div>

                <div className="py-4 container-homepage">
                    <div className="container">
                        <h2>Apprentissage de l'Anglais en Ligne avec Talkeasy</h2>
                        <p>
                            Bienvenue dans notre parcours d'apprentissage de l'anglais. Chez talkeasy.fr, nous vous offrons une méthode innovante et efficace pour maîtriser l'anglais. Que vous soyez débutant ou avancé, nos cours en ligne sont conçus pour répondre à vos besoins et vous aider à atteindre vos objectifs linguistiques.
                        </p>
                    </div>
                </div>
                
                <div className="container">
                    <h2>Une Méthode d'Apprentissage Unique</h2>
                    <p>
                        Nos cours en ligne d'anglais sont conçus pour vous offrir une expérience d'apprentissage efficace et flexible. Notre méthode interactive et personnalisée vous permet de progresser à votre rythme. En plus des cours en ligne, vous aurez accès à une vaste gamme de ressources pour renforcer vos compétences linguistiques.
                    </p>
                </div>
            </>
        );
    }
}

export default withTranslation()(English);
