
import React, {Component} from 'react';
import '../styles/contactus.scss';
import {withTranslation} from "react-i18next";
import Logo from '../images/logo_pp.svg';
import ContactForm from "./contact-form.component";
import MetaTags from "react-meta-tags";

class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.state = {
            showModal: false
        }
    }

    componentDidMount(){
        document.title = 'Nous contacter - talkeasy.fr'
    }

    showModal(){
        this.setState({
            showModal: true
        })
    }
    closeModal(){
        this.setState({
            showModal: false
        })
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Nous contacter - talkeasy.fr</title>
                    <meta name="description" content="talkeasy.fr - Contactez nous par téléphone : 06 27 35 62 95  ou par email contact@talkeasy.fr"/>
                </MetaTags>
                <ContactForm
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                />
                <div className="px-4 pt-5 my-5 text-center border-bottom">
                    <h1 className="display-4 fw-bold">Nous Contacter</h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Appeller nous de 9h à 18h du lundi au samedi. Ou simplement laissez un message et vous seriez
                            recontacté au plus vite
                        </p>
                        <div className="d-grid gap-2 d-sm-flex mb-5">
                            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3 contactbutton" onClick={this.showModal}>
                                Nous contacter
                            </button>
                            <a href={"tel:+33627356295"}>
                                <button type="button" className="btn btn-outline-primary btn-lg px-4 contactbutton">
                                    06 27 35 62 95
                                </button>
                            </a>
                            <a href={"mailto:contact@talkeasy.fr"}>
                                <button type="button" className="btn btn-outline-primary btn-lg px-4 contactbutton">
                                    contact@talkeasy.fr
                                </button>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </>
        )
    }
}

export default withTranslation()(ContactUs);