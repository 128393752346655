import React, {Component} from 'react';
import '../styles/cpf.scss';
import {withTranslation} from "react-i18next";
import englishImage from '../images/cpf.png';
import MetaTags from "react-meta-tags";

class CpfComponent extends Component {

    componentDidMount() {
        document.title = 'Le CPF c\'est quoi - talkeasy.fr'
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Le CPF c'est quoi - talkeasy.fr</title>
                    <meta name="description" content="talkeasy.fr - Le CPF (Compte Personnel de Formation) permet désormais aux salariés, demandeurs d’emplois et aux
                                travailleurs non-salariés de bénéficier via un financement par le CPF de formations d’anglais pour
                                s’améliorer à l’oral et à l’écrit dans un cadre professionnel ou personnel."/>
                </MetaTags>
                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div className="col-10 col-sm-8 col-lg-6">
                            <img src={englishImage} className="d-block mx-lg-auto img-fluid"
                                 alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
                        </div>

                        <div className="col-md-6">
                            <h2>CPF pour apprendre les langues étrangers</h2>
                            <p>
                                Le CPF (Compte Personnel de Formation) bénéficie de nombreux avantages depuis le
                                1er janvier 2019 ! Il permet désormais aux salariés, demandeurs d’emplois et aux
                                travailleurs non-salariés (travailleurs indépendants, membres des professions libérales
                                et des professions non-salariées, artistes auteurs ou conjoints collaborateurs) de
                                bénéficier librement via un financement par le CPF de formations de langues pour
                                s’améliorer à l’oral et à l’écrit dans un cadre professionnel ou personnel.
                                Toutes ces séances de cours pour adultes peuvent se financer à 100% par le biais du
                                compte personnel de formation alimenté chaque année (jusqu’à 8000€ en valeur) et suivi
                                par la CDC (Caisse des Dépôts et Consignations) auxquels les entreprises payent
                                la contribution CPF.
                            </p>
                            <ul className="icon-list">
                                <li>
                                    <a href="/about-us" rel="noopener noreferrer"
                                       target="_blank">
                                        Des Cours Particuliers par les meilleurs professeurs
                                    </a>
                                </li>
                                <li className="text-muted">Jusqu'à 100% financé</li>
                            </ul>
                        </div>

                    </div>
                </div>

            </>
        )
    }
}

export default withTranslation()(CpfComponent);