import React, {Component} from "react";
import UserService from "../../services/user.service";
import '../../styles/admin.scss';
import ProductService from  '../../services/product.service'
import SaleService from '../../services/sale.service'
import LessonService from '../../services/lesson.service'
import ConfigurationsService from  '../../services/configuration.service'
import {withTranslation} from "react-i18next";
import {Link, Route} from "react-router-dom";
import Service from "../../services/service";
class AdminTables extends Component {
    services
    fields
    constructor(props) {
        super(props);
        this.onClickRow = this.onClickRow.bind(this);
        UserService.checkConnected();
        UserService.getRoles().then(response => {
            if (!response.data.includes('admin')) {
                window.location.href = window.location.protocol + "//" + window.location.hostname
            }
        })

        this.state = {
            table: this.props.table,
            response : []
        };
        this.services = {
            'products' : ProductService,
            'users' : UserService,
            'sales' : SaleService,
            'lessons': LessonService,
            'configurations' : ConfigurationsService,
            'group_sessions' : new Service('group_sessions')
        }

        this.fields = {
            'products': [
                "id",
                "name",
                "description",
                "short_description",
                "price_before_tax",
                "price_after_tax",
                "on_sale",
                "rank",
                "price_hourly",
                "enabled",
                "hours_qty",
                "createdAt",
                "updatedAt"
            ],
            'users': [
                "id",
                "username",
                "email",
                "password",
                "first_name",
                "last_name",
                "date_birthday",
                "phone",
                "photo",
                "level",
                "sub_level",
                "language_level"
            ],
            'sales': [
                "id",
                "status",
                "total_before_tax",
                "total_after_tax",
                "discount",
                "invoice_first_name",
                "invoice_last_name"
            ],
            'lessons': [
                "id",
                "datetime",
                "state",
                "createdAt",
                "updatedAt",
                "teacherId",
                "userId"
            ],
            'configurations': [
                "id",
                "path",
                "value",
                "createdAt",
                "updatedAt",
            ],
            'group_sessions': [
                "id",
                "datetime",
                "status",
                "lesson_qty",
                "max_person_qty"
            ]
        }

    }

    componentDidMount() {
        this.initialiseComponent();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.table !== this.props.table) {
                await this.setState({
                    table: this.props.table
                })
            this.initialiseComponent();
        }
    }

    initialiseComponent(){
        if("undefined" !== typeof this.state.table)
        {
            if("undefined" === typeof this.services[this.state.table]){
                this.setState({
                    table: undefined
                })
                return false;
            }
            let Service = this.services[this.props.table];
            if('undefined' !== typeof Service){
                Service.getAllData().then(response=> {
                    this.setState({
                        response : response.data
                    })
                })
            }
        }
    }

    onClickRow(history, id){
        switch (this.state.table) {
            case "sales":
                history.push("/admin/sale/" + id)
                break;
            default:
                history.push("/admin/create/" + this.state.table + '/' + id)
                break;

        }

    }

    render() {
        const {t} = this.props;
        return (
            <>
                {"undefined" !== typeof this.fields[this.state.table] ?
                <div className={'container admin-content'}>
                    <Link aria-current="page" className="nav-link link-dark" to={"/admin/create/"+ this.state.table}>
                        <button className="btn btn-outline-dark" type="button">Créer {this.state.table}</button>
                    </Link>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            {
                                    this.fields[this.state.table].map((element) => (
                                        <th key={element} scope="col">{t(element)}</th>
                                    ))
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.response.map((data) => (
                                <Route key={data.id} render={({history}) => (
                                    <tr onClick={() => {this.onClickRow(history, data.id)}}>
                                        {
                                            this.fields[this.state.table].map((element) => (
                                                <td key={element}>{
                                                    typeof data[element] !== "undefined" && (element === 'datetime' || element === 'createdAt' || element === 'updatedAt')    ?
                                                        new Date(data[element]).toLocaleDateString() + " " + new Date(data[element]).toLocaleTimeString() : (data[element])
                                                }</td>
                                            ))
                                        }
                                    </tr>
                                )}/>
                            ))
                        }

                        </tbody>

                    </table>
                </div>
                       :
                       null
                }
            </>

        );
    }
}
export default withTranslation()(AdminTables)