import React, {Component} from "react";
import UserService from "../../services/user.service";
import NoMatchComponent from "../nomatch.component";
import {Link} from "react-router-dom";
import UserDashboard from "./user_dashboard.component";
import LessonDashboard from './lesson_dashboard.component'

export default class AdminPanel extends Component {
    dashComponents

    constructor(props) {
        super(props);
        this.dashComponents =
            {
                'user': UserDashboard,
                'lesson': LessonDashboard

            }
        this.state = {
            searchValue: "",
            authorized: false,
            pageId: this.props.match.params.pageId,
            currentComponent: typeof this.dashComponents[this.props.match.params.pageId] !== "undefined" ? this.dashComponents[this.props.match.params.pageId] : UserDashboard
        }

        this.handleChangeSearchQuery = this.handleChangeSearchQuery.bind(this)

    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.table !== this.props.match.params.table) {
            this.setState({
                table: this.props.match.params.table
            })
        }
    }

    async componentDidMount() {
        document.title = 'Admin dashboard v2 - talkeasy.fr'
        UserService.checkConnected();
        let roles = await UserService.getRoles();
        if (roles.data.includes('admin')) {
            this.setState({
                authorized: true
            })
        }
    }

    handleChangeSearchQuery(e){
        this.setState({
            searchValue: e.target.value
        })
    }


    render() {
        const SpecificComponent = this.state.currentComponent;
        if(!this.state.authorized) {
            return (<>Not authorised</>)
        }
        return (
            <>
                <div className="py-4 container-homepage">
                    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                        <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/dash">talkeasy dashboard</a>
                        <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <input  value={this.state.searchValue} onChange={this.handleChangeSearchQuery} className="form-control form-control-dark w-100" type="text" placeholder="Search"
                               aria-label="Search"/>
    {/*                    <div className="navbar-nav">
                            <div className="nav-item text-nowrap">
                                <a className="nav-link px-3" href="#">Sign out</a>
                            </div>
                        </div>*/}
                    </header>

                    <div className="container-fluid">
                        <div className="row">
                            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                                <div className="position-sticky pt-3">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <div className="nav-link active">
                                                <span data-feather="home"></span>
                                                Dashboard
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" href="#" to={"/dash/user"} onClick={()=>this.setState({currentComponent: this.dashComponents['user']})}>
                                                <span data-feather="file"></span>
                                                Users
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" href="#" to={"/dash/lesson"} onClick={()=>this.setState({currentComponent: this.dashComponents['lesson']})}>
                                                <span data-feather="shopping-cart"></span>
                                                Lessons
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </nav>

                            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                                {
                                    typeof SpecificComponent !== "undefined" ?
                                        <SpecificComponent
                                            searchValue={this.state.searchValue}
                                        />
                                        : null
                                }

                            </main>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
