import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";


class VisitorService {

    pushVisitorEvent() {
        return axios.post(Addr.apiAddress() + "/api/visitor", {
                page: window.location.href
            },
            {headers: authHeader()});
    }



}


export default new VisitorService();
