import React, {Component} from "react";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import {withTranslation} from "react-i18next";
import UserService from "../../services/user.service";
import AdminNavbar from "./admin_navbar.component";
import SaleService from "../../services/sale.service"

class AdminSaleComponent extends Component {
    services
    service

    constructor(props) {
        super(props);
        UserService.checkConnected();
        UserService.getRoles().then(response => {
            if (!response.data.includes('admin')) {
                window.location.href = window.location.protocol + "//" + window.location.hostname
            }
        })


        this.state = {
            values: {},
            successful: false,
            message: "",
            sale: {},
            paymentData: []
        };
    }

    async componentDidMount() {
        let sale = (await SaleService.getDataById(this.props.match.params.saleId)).data;
        sale.payment_data = JSON.parse(sale.payment_data)
        for (var i = 0; i < sale.payment_data.length; i++) {
            sale.payment_data[i].payment_data =  JSON.parse(sale.payment_data[i].payment_data)
        }

        await this.setState({
            sale: sale,
            paymentData: sale.payment_data
        })


    }

    render() {
        return (
            <>
                <AdminNavbar/>
                <div className={"container"}>
                    <div className="col-md-12">

                        <div className="accordion" id="accordionExample">

                                {
                                    this.state.paymentData.map((paymentDataElement) =>(
                                        <div className="accordion-item" key={paymentDataElement.id}>
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#collapse"+ paymentDataElement.id} aria-expanded="false"
                                                        aria-controls="collapseOne">
                                                    Payment {paymentDataElement.id}
                                                </button>
                                            </h2>
                                        <div id={"collapse"+ paymentDataElement.id} className="accordion-collapse collapse"
                                             aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <JSONPretty id="json-pretty" data={paymentDataElement}></JSONPretty>
                                            </div>
                                        </div>
                                        </div>
                                    ))
                                }


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                        User Client
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse"
                                     aria-labelledby="headingTwo"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <JSONPretty id="json-pretty" data={this.state.sale.user_client}></JSONPretty>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default withTranslation()(AdminSaleComponent)