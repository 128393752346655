import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class QuoteService {

    create(data) {
        return axios.post(Addr.apiAddress() + "/api/quote/create", {
                products : data,
            },
            {headers: authHeader()});
    }

    async getAllData(){
        return axios.post(Addr.apiAddress() + "/api/quote/get",{},{headers: authHeader()});
    }

    setDataById(id, data){
        return axios.post(Addr.apiAddress() + "/api/quote/modify", {
                id : id,
                name : data.name,
                description: data.description,
                short_description: data.short_description,
                price_before_tax: data.price_before_tax,
                price_after_tax: data.price_after_tax,
                on_sale: data.on_sale,
                rank: data.rank,
                price_hourly: data.price_hourly,
                enabled: data.enabled,
            },
            {headers: authHeader()});
    }

    getDataById(){
        return axios.post(Addr.apiAddress() + "/api/quote/id", {
            },
            {headers: authHeader()});
    }
    delete(id){
        return axios.post(Addr.apiAddress() + "/api/quote/delete", {
                id : id
            },
            {headers: authHeader()});
    }
}
export default new QuoteService();
