import React, {Component} from 'react';
import '../../styles/home.scss';
import {withTranslation} from "react-i18next";
import MetaTags from 'react-meta-tags';

class Italian extends Component {

    constructor() {
        super();

        this.state = {
            modalAdditionalMessage: ""
        }

    }

    componentDidMount() {
        document.title = 'Cours de langues étrangers particuliers - talkeasy.fr'
    }


    render() {
        return (
            <>
                <MetaTags>
                    <title>Apprendre l'Italien en Ligne - talkeasy.fr</title>
                    <meta name="description" content="Découvrez pourquoi notre méthode d'apprentissage de l'italien en ligne est la meilleure. Apprenez de manière efficace avec talkeasy.fr." />
                </MetaTags>
                <div className="py-4 container-homepage">
                    <div className="container">
                        <h2>Apprendre l'Italien en Ligne</h2>
                        <p>
                            Chez talkeasy.fr, nous sommes fiers de vous proposer la meilleure méthode d'apprentissage de l'italien en ligne. Découvrez pourquoi nos cours en ligne sont la solution idéale pour maîtriser cette langue :
                        </p>
                    </div>
                </div>
                <div className="row g-3 text-center div-circles">
                    <div className="card" style={{ width: '18rem' }}>
                        <i className="bi bi-star-fill primary-color" style={{ fontSize: '50px' }}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Méthode d'enseignement efficace et reconnue
                            </p>
                        </div>
                    </div>
                    <div className="card" style={{ width: '18rem' }}>
                        <i className="bi bi-flag-fill primary-color" style={{ fontSize: '50px' }}></i>
                        <div className="card-body">
                            <p className="card-text">Cours adaptés à votre progression et à vos objectifs</p>
                        </div>
                    </div>
                    <div className="card" style={{ width: '18rem' }}>
                        <i className="bi bi-book-half primary-color" style={{ fontSize: '50px' }}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Accès à des ressources d'apprentissage de qualité supérieure
                            </p>
                        </div>
                    </div>
                </div>
                <div className="py-4 container-homepage">
                    <div className="container">
                        <h2>Pourquoi Nos Cours en Ligne Sont les Meilleurs pour l'Italien</h2>
                        <p>
                            Nos cours en ligne d'italien sont conçus pour vous offrir une expérience d'apprentissage efficace et flexible. Notre méthode efficace et reconnue vous permet de progresser rapidement. Nous adaptons nos cours en fonction de votre niveau et de vos objectifs spécifiques, garantissant ainsi une expérience d'apprentissage personnalisée. De plus, vous aurez accès à une vaste gamme de ressources d'apprentissage de qualité supérieure pour renforcer vos compétences linguistiques. Rejoignez talkeasy.fr dès aujourd'hui et découvrez pourquoi nous sommes la meilleure option pour l'apprentissage de l'italien en ligne !
                        </p>
                    </div>
                </div>
            </>
        );
    }




}

export default withTranslation()(Italian);