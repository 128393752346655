import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css';
import * as serviceWorker from './serviceWorker';
import {Router} from "react-router-dom"
import {createBrowserHistory} from 'history'
import './i18n';

// Create custom history
const history = createBrowserHistory()

ReactDOM.render((
        <Router history={history}>
            <App/>
        </Router>),
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

