import React, {Component} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {withTranslation} from 'react-i18next';
import AuthService from "../services/auth.service";
import {Link} from "react-router-dom";
import UserService from "../services/user.service";
import MetaTags from "react-meta-tags";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Ce champ est obligatoire.
            </div>
        );
    }
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            email: "",
            password: "",
            loading: false,
            message: ""
        };
    }

    async componentDidMount() {
        document.title = 'Connexion - talkeasy.fr'
        let connected = await UserService.isConnected()
        if (connected) {
            this.props.history.push("/");
        }
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }
    fix(e) {
        document.querySelector('[name="'+e.target.name+'"]').setAttribute('value', '');
    }

    async handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            try {
                await AuthService.login(this.state.email, this.state.password);
                this.props.history.push('/')
                window.location.reload();
            } catch (error) {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage
                });
            }
        } else {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className="col-md-12">
                <MetaTags>
                    <title>Connexion - talkeasy.fr</title>
                    <meta name="description" content="talkeasy.fr - Connectez vous à votre espace personnel et reservez les cours en ligne"/>
                </MetaTags>
                <div className="card card-container">
                    <Form
                        onSubmit={this.handleLogin}
                        ref={c => {
                            this.form = c;
                        }} className="form-signin"
                        autoComplete="on"
                    >
                        <div className="mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="currentColor"
                                 className="bi bi-person-square" viewBox="0 0 16 16">
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                <path
                                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z"/>
                            </svg>
                        </div>
                        <h1 className="h3 mb-3 font-weight-normal">{t('Please sign in')}</h1>
                        <div className="form-group">
                            <label htmlFor="Email">{t('Email')}</label>
                            <Input
                                type="email"
                                className="form-control"
                                name="email"
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                                validations={[required]}
                                autoComplete="on"
                                onClick={this.fix}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">{t('Password')}</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={this.state.password}
                                onChange={this.onChangePassword}
                                validations={[required]}
                                autoComplete="on"
                                onClick={this.fix}
                            />
                        </div>

                        <div className="form-group">
                            <button
                                className="w-100 btn btn-lg btn-primary pp-button-home-level"
                                disabled={this.state.loading}
                            >
                                {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>{t('Login')}</span>
                            </button>

                            <Link aria-current="page" className="w-100 btn btn-lg btn-outline-primary pp-button-home-level" to={"/register"} onClick={this.onClickCloseSidebar}>
                                {t("S'inscrire")}
                            </Link>


                        </div>

                        {this.state.message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {this.state.message}
                                </div>
                            </div>
                        )}
                        <CheckButton
                            style={{display: "none"}}
                            ref={c => {
                                this.checkBtn = c;
                            }}
                        />
                    </Form>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Login)
