import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class GroupService {


    async getOpen(){
        return axios.post(Addr.apiAddress() + "/api/group_sessions/getOpen", {},{headers: authHeader()});
    }

    async getMyLessons(){
        return axios.post(Addr.apiAddress() + "/api/group_sessions/getMyLessons", {},{headers: authHeader()});
    }

    async signIn(groupSessionId){
        return axios.post(Addr.apiAddress() + "/api/group_sessions/signIn", {id : groupSessionId},{headers: authHeader()});
    }

    async signOut(groupSessionId){
        return axios.post(Addr.apiAddress() + "/api/group_sessions/signOut", {id : groupSessionId},{headers: authHeader()});
    }

    async getUserGroupSessions(){
        return axios.post(Addr.apiAddress() + "/api/group_sessions/getUserGroupSessions", {},{headers: authHeader()});
    }

}
export default new GroupService();
