
import React, {Component} from 'react';
import '../styles/cpf.scss';
import {withTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
class LoaderComponent extends Component {

    componentDidMount() {
        document.title = 'Le CPF c\'est quoi - talkeasy.fr'
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} className="modal-loader" dialogClassName="modal-dialog-centered" contentClassName="modal-loader">
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border primary-color" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                </Modal>
            </>
        )
    }
}

export default withTranslation()(LoaderComponent);