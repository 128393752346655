import React, {Component} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import '../styles/cpf.scss';
import {withTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {isEmail} from "validator";
import CheckButton from "react-validation/build/button";
import CallbackService from "../services/callback.service"
import LoaderComponent from "./loader.component";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {isValidPhoneNumber} from "react-phone-number-input";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Ce champ est obligatoire.
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSend = this.handleSend.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirst_name = this.onChangeFirst_name.bind(this);
        this.onChangeLast_name = this.onChangeLast_name.bind(this);
        this.onChangeMessage = this.onChangeMessage.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);

        this.state = {
            show: false,
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
            userMessage: "",
            successful: false,
            message: "",
            loading: false,
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show !== prevProps.show) {
            this.setState(prevState => ({
                show: this.props.show
            }));
        }
    }

    handleShow() {
        this.setState({
            show: true
        })
    }

    async handleClose() {
        await this.setState({
            show: false
        })
        this.setState({
            show: false,
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
            userMessage: "",
            successful: false,
            message: "",
            loading: false,
        })
        this.props.handleClose();
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangeFirst_name(e) {
        this.setState({
            first_name: e.target.value
        });
    }

    onChangeLast_name(e) {
        this.setState({
            last_name: e.target.value
        });
    }

    onChangeMessage(e) {
        this.setState({
            userMessage: e.target.value
        });
    }

    onChangePhone(e) {
        this.setState({
            phone: e.target.value
        });
    }

    async handleSend(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0 && isValidPhoneNumber(this.state.phone)) {
            this.setState({loading: true})
            if (typeof this.props.additionalMessage !== 'undefined') {
                await this.setState({
                    userMessage: this.props.additionalMessage + this.state.userMessage
                })
            }
            CallbackService.callback(
                this.state.first_name,
                this.state.last_name,
                this.state.email,
                this.state.phone,
                this.state.userMessage
            ).then(
                response => {
                    this.setState({
                        message: response.data.message,
                        successful: true
                    });

                    this.setState({
                        message: 'Merci. Vous allez être recontacté prochainement.',
                        loading: false
                    });

                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        successful: false,
                        message: resMessage,
                        loading: false
                    });
                }
            );
        } else {
            if (!isValidPhoneNumber(this.state.phone)) {
                this.setState({
                    phoneSuccessful: false,
                    phoneMessage: "Numèro de téléphone invalide",
                    loading: false
                });
            }
        }
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <LoaderComponent
                    show={this.state.loading}/>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <div className="modal-content rounded-5 shadow">
                        <div className="modal-header p-5 pb-4 border-bottom-0">
                            <h2 className="fw-bold mb-0">
                                Nous allons nous mettre en contact avec vous
                            </h2>
                            <button type="button" className="btn-close"
                                    aria-label="Close" onClick={this.handleClose}></button>
                        </div>

                        <div className="modal-body p-5 pt-0">
                            <Form
                                onSubmit={this.handleSend}
                                ref={c => {
                                    this.form = c;
                                }} className="form-signin"
                            >
                                {!this.state.successful && (
                                    <>

                                        <div className="form-group">
                                            <label htmlFor="firstName">{t("Prénom *")}</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="firstName"
                                                value={this.state.firstName}
                                                onChange={this.onChangeFirst_name}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="last_name">{t("Nom *")}</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="lastName"
                                                value={this.state.last_name}
                                                onChange={this.onChangeLast_name}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="email">{t("Email *")}</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.onChangeEmail}
                                                validations={[required, email]}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="phone">{t("Numéro de téléphone *")}</label>
                                            <PhoneInput
                                                defaultCountry={'FR'}
                                                value={this.state.phone}
                                                onChange={phone => this.setState({ phone: phone, phoneMessage: '' })}
                                                required
                                            />
                                            {this.state.phoneMessage && (
                                                <div
                                                    className={
                                                        this.state.phoneSuccessful
                                                            ? "alert alert-success"
                                                            : "alert alert-danger"
                                                    }
                                                    role="alert"
                                                >
                                                    {this.state.phoneMessage}
                                                </div>
                                            )}
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="userMessage">{t("Laissez un message")}</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="userMessage"
                                                value={this.state.userMessage}
                                                onChange={this.onChangeMessage}
                                            />
                                        </div>

                                        <small className="text-muted">
                                            En cliquant Rappellez-moi vous acceptez que nous allons vous rappeller
                                        </small>

                                        <button className="w-100 mb-2 btn btn-lg rounded-4 btn-primary" type="submit">
                                            Valider
                                        </button>


                                    </>
                                )}

                                {this.state.message && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                this.state.successful
                                                    ? "alert alert-success"
                                                    : "alert alert-danger"
                                            }
                                            role="alert"
                                        >
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton
                                    style={{display: "none"}}
                                    ref={c => {
                                        this.checkBtn = c;
                                    }}
                                />

                            </Form>
                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}

export default withTranslation()(ContactForm);