import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class Service {
    name
    constructor(name) {
        this.name = name
    }

    create(data) {
        return axios.post(Addr.apiAddress() + "/api/"+ this.name +"/create", data,
            {headers: authHeader()});
    }

    async getAllData() {
        return axios.post(Addr.apiAddress() + "/api/"+ this.name +"/get", {}, {headers: authHeader()});
    }

    setDataById(id, data) {
        data.id= id
        return axios.post(Addr.apiAddress() + "/api/"+ this.name +"/modify", data,
            {headers: authHeader()});
    }

    getDataById(id) {
        return axios.post(Addr.apiAddress() + "/api/"+ this.name +"/id", {
                id: id
            },
            {headers: authHeader()});

    }

    delete(id) {
        return axios.post(Addr.apiAddress() + "/api/"+ this.name +"/delete", {
                id: id
            },
            {headers: authHeader()});
    }

    getByData(data){
        return axios.post(Addr.apiAddress() + "/api/"+ this.name +"/getByData", data,
            {headers: authHeader()});
    }
}

export default Service;
