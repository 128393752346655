import React, {Component} from 'react';
import '../styles/cpf.scss';
import {withTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import LoaderComponent from "./loader.component";
import 'react-phone-number-input/style.css'


class PPModal extends Component {

    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)

        this.state = {
            show: false,
            loading: false,
            alertSuccess: false,
            alert: false,
            alertMessage: "",
            modalName: '',
            modalContent: ""
        }

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {

            await this.setState(prevState => ({
                show: this.props.show,
                alertSuccess: this.props.alertSuccess,
                alert: this.props.alert,
                alertMessage: this.props.alertMessage,
                modalName: this.props.modalName,
                modalContent: this.props.modalContent
            }));
            console.log(this.state)
        }
    }

    handleShow() {
        this.setState({
            show: true
        })
    }

    async handleClose() {
        await this.setState({
            show: false
        })
        this.props.handleClose();
    }


    render() {
        return (
            <>
                <LoaderComponent
                    show={this.state.loading}/>
                <Modal show={this.state.show} onHide={this.handleClose} centered>
                    <div className="modal-content rounded-5 shadow">
                        <div className="modal-header p-5 pb-4 border-bottom-0">
                            <h2 className="fw-bold mb-0">
                                {this.state.modalName}
                            </h2>
                            <button type="button" className="btn-close"
                                    aria-label="Close" onClick={this.handleClose}></button>
                        </div>

                        <div className="modal-body p-5 pt-0">
                            {
                                this.state.alert === true ?
                                    <div className={"alert alert-" + this.state.alertSuccess} role="alert">
                                        {this.state.alertMessage}
                                    </div>
                                    :
                                    this.state.modalContent
                            }
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withTranslation()(PPModal);