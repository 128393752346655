import React, {Component} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";
import {withTranslation} from "react-i18next";
import UserService from "../../services/user.service";
import ProductService from '../../services/product.service'
import LessonService from '../../services/lesson.service'
import ConfigurationService from '../../services/configuration.service'
import AdminNavbar from "./admin_navbar.component";
import Textarea from "react-validation/build/textarea";
import Service from "../../services/service";
import '../../styles/admin.scss';
import AdminTableList from "./admin_table_list.component"
class AdminCreate extends Component {
    services
    service
    selectValues
    tableFields

    constructor(props) {
        super(props);

        UserService.checkConnected();
        UserService.getRoles().then(response => {
            if (!response.data.includes('admin')) {
                window.location.href = window.location.protocol + "//" + window.location.hostname
            }
        })

        this.handleRegister = this.handleRegister.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.onChangeField = this.onChangeField.bind(this);


        var tables = {
            'products': [
                "name",
                "description",
                "short_description",
                "price_hourly",
                "price_before_tax",
                "price_after_tax",
                "on_sale",
                "rank",
                "hours_qty",
                "enabled",
                "is_group"
            ],
            'users': [
                "username",
                "email",
                "password",
                "first_name",
                "last_name",
                "date_birthday",
                "phone",
                "photo",
                "level",
                "sub_level",
                "language_level",
                "lessons_balance",
                "group_lessons_balance"
            ],
            'lessons': [
                "id",
                "datetime",
                "state",
                "createdAt",
                "updatedAt",
                "teacherId",
                "userId"
            ],
            'configurations': [
                "id",
                "path",
                "value",
                "createdAt",
                "updatedAt"
            ],
            "group_sessions": [
                "status",
                "datetime",
                "lesson_qty",
                "max_person_qty",
                "group_sessions_lessons"
            ],
            'group_sessions_lessons':[
                "groupSessionId",
                "datetime",
                "teacherId"
            ]
        }


        var tableValueType = {
            'products': {
                "name": "text",
                "description": "textarea",
                "short_description": "textarea",
                "price_hourly": "number",
                "price_before_tax": "number",
                "price_after_tax": "number",
                "on_sale": "number",
                "rank": "number",
                "hours_qty": "number",
                "enabled": "number",
                "is_group": "select"
            },
            'users': {
                "username": "text",
                "email": "email",
                "password": "text",
                "first_name": "text",
                "last_name": "text",
                "date_birthday": "date",
                "phone": "phone",
                "photo": "text",
                "level": "text",
                "sub_level": "text",
                "language_level": "text",
                "lessons_balance": "number",
                "group_lessons_balance": "number"
            },
            'lessons': {
                "id": "text",
                "datetime": "datetime-local",
                "state": "select",
                "createdAt": "datetime-local",
                "updatedAt": "datetime-local",
                "teacherId": "text",
                "userId": "text"
            },
            'configuration': {
                "id": "text",
                "path": "text",
                "value": "text",
                "createdAt": "datetime-local",
                "updatedAt": "datetime-local",
            },
            'group_sessions': {
                "datetime": "datetime-local",
                "lesson_qty": "number",
                "max_person_qty": "number",
                "status": "select",
                "group_sessions_lessons": "table"
            },
            'group_sessions_lessons':{
                "groupSessionId": "number",
                "datetime": "datetime-local",
                "teacherId": "number"
            }
        }

        this.selectValues = {
            'lessons': {
                'state': ['pending', 'approved', 'rejected']
            },
            'products': {
                'is_group': ['0', '1']
            },
            'group_sessions': {
                'status': ['open', 'closed', 'full', 'completed']
            }
        }


        this.services = {
            'products': ProductService,
            'users': UserService,
            'lessons': LessonService,
            'configurations': ConfigurationService,
            'group_sessions': new Service('group_sessions'),
            'group_sessions_lessons': new Service('group_sessions_lessons'),
        }

        this.tableFields = {
            'group_sessions_lessons': [
                "datetime",
                "teacherId"
            ]
        }

        this.state = {
            table: this.props.match.params.table,
            table_values: tables.hasOwnProperty(this.props.match.params.table) ? tables[this.props.match.params.table] : [],
            table_values_types: tableValueType.hasOwnProperty(this.props.match.params.table) ? tableValueType[this.props.match.params.table] : [],
            values: {},
            successful: false,
            message: "",
        };



    }

    componentDidMount() {

        if ("undefined" !== typeof this.state.table) {
            if (!this.services.hasOwnProperty(this.state.table)) {
                this.setState({
                    table: undefined
                })
                return false;
            }
            this.service = this.services[this.state.table]
        }
        let values = this.state.values
        new URLSearchParams(this.props.location.search).forEach(function(value, key) {
            values[key] = value
        });
        this.setState({
            values : values
        })
        if ("undefined" !== typeof this.props.match.params.entityId) {
            this.service.getDataById(this.props.match.params.entityId).then(
                response => {
                    this.setState({
                        values: response.data
                    });
                },
                error => {
                    alert('Une erreur s\'est produite');
                }
            );
        }
    }

    onChangeField(e) {
        let values = this.state.values
        values[e.target.name.toString()] = e.target.value
        this.setState({
            values: values
        })
    }

    handleDelete() {
        this.service.delete(
            this.state.values.id
        ).then(
            response => {
                this.props.history.push("/admin/" + this.props.match.params.table);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    successful: false,
                    message: resMessage
                });
            }
        );
    }

    handleRegister(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {

            if ("undefined" === typeof this.props.match.params.entityId) {
                this.service.create(
                    this.state.values
                ).then(
                    response => {
                        this.setState({
                            message: response.data.message,
                            successful: true
                        });
                        this.props.history.push("/admin/" + this.props.match.params.table);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.setState({
                            successful: false,
                            message: resMessage
                        });
                    }
                );
            } else {
                this.service.setDataById(
                    this.props.match.params.entityId,
                    this.state.values
                ).then(
                    response => {
                        this.setState({
                            message: response.data.message,
                            successful: true
                        });
                        this.props.history.push("/admin/" + this.props.match.params.table);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.setState({
                            successful: false,
                            message: resMessage
                        });
                    }
                );
            }
        }
    }

    getDateFormat(date, element) {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (typeof date !== 'undefined') {
            return new Date(new Date(date) - tzoffset).toISOString().substring(0, 19);
        }else{
            let vals = this.state.values
            vals[element] = new Date(new Date() - tzoffset).toISOString().substring(0, 19)
            this.setState({
                values: vals
            })

            return new Date().toISOString().substring(0, 19);
        }
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="py-4 container-homepage">
                    <AdminNavbar/>
                    <div className="col-md-12">

                        <div className="card card-container">
                            {"undefined" !== typeof this.props.match.params.entityId ?
                                <div className={'form-signin'}>
                                    <button
                                        className="w-100 btn btn-lg btn-outline-dark"
                                        onClick={this.handleDelete}>
                                        Supprimer
                                    </button>
                                </div>
                                : null

                            }


                            <Form
                                onSubmit={this.handleRegister}
                                ref={c => {
                                    this.form = c;
                                }} className="form-signin"
                            >

                                <h1 className="h3 mb-3 font-weight-normal">{t('Gestion - ' + this.state.table)}</h1>
                                <br/>
                                {!this.state.successful && (
                                    <div>
                                        {
                                            this.state.table_values.map((element) => (
                                                <div key={element} className="form-group">

                                                    {this.state.table_values_types[element] === 'textarea' ?
                                                        <>
                                                            <label htmlFor="firstName">{t(element)}</label>
                                                            <Textarea
                                                                type={this.state.table_values_types[element]}
                                                                className="form-control"
                                                                name={element}
                                                                value={this.state.values[element]}
                                                                onChange={this.onChangeField}
                                                                validations={[]}
                                                            />
                                                        </>
                                                        :
                                                        this.state.table_values_types[element] === 'select' ?
                                                            <>
                                                                <label htmlFor="firstName">{t(element)}</label>
                                                                <Select required className="form-select" name={element}
                                                                        value={this.state.values[element]}
                                                                        onChange={this.onChangeField}>
                                                                    <option disabled value={""}>--</option>
                                                                    {this.selectValues[this.state.table][element].map((element) => (
                                                                        <option value={element}>{element}</option>
                                                                    ))}
                                                                </Select>
                                                            </>
                                                            :

                                                            this.state.table_values_types[element] === 'datetime-local' ?
                                                                <>
                                                                    <label htmlFor="firstName">{t(element)}</label>
                                                                    <Input
                                                                        type={this.state.table_values_types[element]}
                                                                        className="form-control"
                                                                        name={element}
                                                                        value={this.getDateFormat(this.state.values[element], element)}
                                                                        onChange={this.onChangeField}
                                                                        validations={[]}
                                                                    />
                                                                </>

                                                                :
                                                                this.state.table_values_types[element] === 'table' ?
                                                                    "undefined" !== typeof this.props.match.params.entityId ?
                                                                        <>
                                                                            <label
                                                                                htmlFor="firstName">{t(element)}</label>
                                                                            <AdminTableList
                                                                                table={element}
                                                                                fields={this.tableFields[element]}
                                                                                service={new Service(element)}
                                                                                tableRowId={this.props.match.params.entityId}
                                                                                tableRowName={"groupSessionId"}
                                                                                history={this.props.history}
                                                                            />
                                                                        </>
                                                                        : null
                                                                    :
                                                                    <>
                                                                        <label htmlFor="firstName">{t(element)}</label>
                                                                        <Input
                                                                            type={this.state.table_values_types[element]}
                                                                            className="form-control"
                                                                            name={element}
                                                                            value={this.state.values[element]}
                                                                            onChange={this.onChangeField}
                                                                            validations={[]}
                                                                        />
                                                                    </>
                                                    }
                                                </div>
                                            ))
                                        }
                                        <div className="form-group">
                                            <button type="submit"
                                                className="w-100 btn btn-lg btn-primary pp-button-home-level">
                                                Valider
                                            </button>
                                        </div>

                                    </div>
                                )}

                                {this.state.message && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                this.state.successful
                                                    ? "alert alert-success"
                                                    : "alert alert-danger"
                                            }
                                            role="alert"
                                        >
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton
                                    style={{display: "none"}}
                                    ref={c => {
                                        this.checkBtn = c;
                                    }}
                                />
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(AdminCreate)