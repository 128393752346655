import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class SaleService {

    create(data) {
        return axios.post(Addr.apiAddress() + "/api/sale/create", data,
            {headers: authHeader()});
    }

    getEnv(){
        return axios.post(Addr.apiAddress() + "/api/sale/getenv",{},
            {headers: authHeader()});
    }

    async createPayment(
        invoice_first_name,
        invoice_last_name,
        invoice_address_1,
        invoice_address_2,
        invoice_country,
        invoice_zipcode,
        invoice_city,
        country_code
    ){
        return axios.post(Addr.apiAddress() + "/api/sale/createPayment",
            {
                invoice_first_name : invoice_first_name,
                invoice_last_name : invoice_last_name,
                invoice_address_1: invoice_address_1,
                invoice_address_2: invoice_address_2,
                invoice_country: invoice_country,
                invoice_zip: invoice_zipcode,
                invoice_city: invoice_city,
                country_code: country_code

            }
            ,{headers: authHeader()});
    }

    async updateStatus(
        paymentId,
        saleId
    ){
        return axios.post(Addr.apiAddress() + "/api/sale/updateStatus",
            {
                paymentId : paymentId,
                saleId: saleId
            }
            ,{headers: authHeader()});
    }

    async getOrder(
        orderId
    ){
        return axios.post(Addr.apiAddress() + "/api/sale/getOrder",
            {
                orderId : orderId
            }
            ,{headers: authHeader()});
    }

    async getAllData(){
        return axios.post(Addr.apiAddress() + "/api/sale/get",{},{headers: authHeader()});
    }

    async getDataById(id){
        return axios.post(Addr.apiAddress() + "/api/sale/id", {
            id:id
            },
            {headers: authHeader()});
    }






}
export default new SaleService();
