import React from "react"
import {
    Switch, Route
    //,  Link
} from "react-router-dom";

import 'jquery/dist/jquery.min.js'
import $ from "jquery";

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import "./App.css";
import './styles/app.scss';
import './styles/footer.css';

import {withTranslation} from "react-i18next";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import Sidebar from "./components/sidebar.component";
import Menu from "./components/menu.component";
import Footer from "./components/footer.component";
import NavbarBottom from "./components/navbar-bottom.component";
import Tarif from "./components/tarif.component";
import Register from "./components/register.component";
import Admin from "./components/admin/admin_panel.component";
import AdminCreate from "./components/admin/admin_create.component";
import Checkout from "./components/checkout.component";
import ThankYou from "./components/thankyou.component";
import AdminSaleComponent from "./components/admin/admin_sale.component"
import CpfComponent from "./components/cpf.component";
import AboutUs from "./components/about-us.component";
import ContactUs from "./components/contact-us.component";
import LessonList from "./components/lessons/list.component";
import Booking from "./components/lessons/booking.component";
import UserService from "./services/user.service";
import Group from "./components/group/index.component";
import AdminDashboard from "./components/admin_dashboard/index.component"
import NoMatchComponent from "./components/nomatch.component";
import visitorService from "./services/visitor.service";
import GroupIntro from "./components/intro/groupe.component"
import IndividuelComponent from "./components/intro/individuel.component";
import English from './components/languages/english.component'
import Itaian from './components/languages/italian.component'
class App extends React.Component {

    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
            reload: false
        };
    }

    async componentDidMount() {
        await visitorService.pushVisitorEvent();
        let connected = await UserService.isConnected()
        if (!connected) {
            localStorage.removeItem("user")
        }

        const user = AuthService.getCurrentUser();
        //document.title = 'Cours de langues étrangers pour tous - talkeasy.fr'
        if (user) {
            this.setState({
                currentUser: user,
                showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                showAdminBoard: user.roles.includes("ROLE_ADMIN"),
            });
        }

        this.props.history.listen((location, action) => {
            // location is an object like window.location
            console.log(action, location.pathname, location.state)
        });
    }

    logOut() {
        AuthService.logout();
    }

    closeOverlay() {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
    }


    render() {
        //  const { currentUser, showModeratorBoard, showAdminBoard } = this.state;


        return (
            <main>
                <div className="overlay" id='overlay' onClick={this.closeOverlay}></div>
                <Sidebar/>
                <Menu/>
                <div id="content">
                    <Switch>
                        <Route exact path={["/", "/home"]} component={Home}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/profile" component={Profile}/>
                        <Route exact path="/register/:type" component={Register}/>
                        <Route exact path="/register" component={Register}/>
                        <Route exact path="/tarif" component={Tarif}/>
                        <Route exact path="/admin/:table" component={Admin}/>
                        <Route exact path="/admin" component={Admin}/>
                        <Route exact path="/admin/create/:table" component={AdminCreate}/>
                        <Route exact path="/admin/create/:table/:entityId" component={AdminCreate}/>
                        <Route exact path="/checkout" component={Checkout}/>
                        <Route exact path="/thankyou" component={ThankYou}/>
                        <Route exact path="/admin/sale/:saleId" component={AdminSaleComponent}/>
                        <Route exact path="/cpf" component={CpfComponent}/>
                        <Route exact path="/about-us" component={AboutUs}/>
                        <Route exact path="/contact-us" component={ContactUs}/>
                        <Route exact path="/lessons" component={LessonList}/>
                        <Route exact path="/book" component={Booking}/>
                        <Route exact path="/group" component={Group}/>
                        <Route exact path="/dash" component={AdminDashboard}/>
                        <Route exact path="/dash/:pageId" component={AdminDashboard}/>
                        <Route exact path="/intro/groupe" component={GroupIntro}/>
                        <Route exact path="/intro/individuel" component={IndividuelComponent}/>
                        <Route exact path="/intro/individuel" component={IndividuelComponent}/>
                        <Route exact path="/english" component={English}/>
                        <Route exact path="/italian" component={Itaian}/>
                        <Route>
                            <NoMatchComponent/>
                        </Route>
                    </Switch>
                    <Footer/>
                </div>
                <NavbarBottom/>
            </main>

        );
    }
}

export default withTranslation()(App);