import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class ConfigurationService {

    create(data) {
        return axios.post(Addr.apiAddress() + "/api/configuration/create", {
                path : data.path,
                value: data.value
            },
            {headers: authHeader()});
    }

    async getAllData(){
        return axios.post(Addr.apiAddress() + "/api/configuration/get",{},{headers: authHeader()});
    }

    setDataById(id, data){
        return axios.post(Addr.apiAddress() + "/api/configuration/modify", {
                id: id,
                path : data.path,
                value: data.value
            },
            {headers: authHeader()});
    }

    getDataById(id){
        return axios.post(Addr.apiAddress() + "/api/configuration/id", {
                id : id
            },
            {headers: authHeader()});
    }
    delete(id){
        return axios.post(Addr.apiAddress() + "/api/configuration/delete", {
                id : id
            },
            {headers: authHeader()});
    }
}
export default new ConfigurationService();
