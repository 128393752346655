import React, {Component} from 'react';
import '../styles/home.scss';
import {withTranslation} from "react-i18next";
import englishImage from '../images/london.svg';
import {Link} from "react-router-dom";
import ContactForm from "./contact-form.component";
import MetaTags from 'react-meta-tags';

class HomeComponent extends Component {

    constructor() {
        super();
        this.showUseCpf = this.showUseCpf.bind(this)
        this.closeUseCpf = this.closeUseCpf.bind(this)
        this.state = {
            showUseCpf: false,
            modalAdditionalMessage: ""
        }

    }

    componentDidMount() {
        document.title = 'Cours de langues étrangers particuliers - talkeasy.fr'
    }

    closeUseCpf() {
        this.setState({
            showUseCpf: false
        })
    }

    showUseCpf() {
        this.setState({
            showUseCpf: true
        })
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Cours de langues étraners - talkeasy.fr</title>
                    <meta name="description" content="Cours de langues étrangers avec talkeasy.fr.
                    L'apprentissage des langues étrangers en ligne est encore plus facile et abordable avec talkeasy.fr
                Nous nous adaptons à vous et nous dévélopons pour chaque élève une approche unique.
                Nous vous proposons des cours individuels et les cours en groupe"/>
                </MetaTags>
                <ContactForm
                    show={this.state.showUseCpf}
                    handleClose={this.closeUseCpf}
                    additionalMessage={this.state.modalAdditionalMessage}
                />
                {/*    <div className={"img-jumbotron"}>
                </div>*/}
                <div className="py-4 container-homepage">
                    <div className={"container   div-jumbotron-form"}>
                        <div className="row   p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                            <div className="col-lg-6 mx-auto">
                                <h2>Cours d'anglais à distance</h2>
                                <p>
                                    Travailler, Voyager, Étudier, Faire des amis : quelles que soient vos raisons pour apprendre les langues étrangères, nos professeurs particuliers sont là pour vous aider à atteindre vos objectifs!
                                </p>
                                <ul className="icon-list">
                                    <li className="text-muted">
                                        Nos professeurs vous apprennent l'anglais ou l'italien.
                                    </li>
                                    <li>
                                        <div className="link-primary"
                                             onClick={() => {
                                                 this.setState({modalAdditionalMessage: "Demande du premier cours : "});
                                                 this.showUseCpf()
                                             }}>
                                            Cours d'essai offert pour découvrir notre approche pédagogique
                                        </div>

                                    </li>
                                    <li className="text-muted">
                                        Offres personnalisées adaptées à vos besoins spécifiques
                                    </li>
                                    <li className="text-muted">
                                        Cours disponibles pour tous les niveaux, du débutant à l'avancé
                                    </li>
                                    <li className="text-muted">
                                        Cours particuliers ou en groupe, choisissez ce qui vous convient le mieux
                                    </li>
                                </ul>
                                <div className="p-4 p-md-5 border rounded-3 bg-light">
                                    <div className="w-100 btn btn-lg btn-primary pp-button-home-level" onClick={() => {
                                        this.setState({ modalAdditionalMessage: "Demande de cours d'essai : " });
                                        this.showUseCpf();
                                    }}>
                                        DEMANDEZ UN COURS D'ESSAI
                                    </div>

                                    <hr className="my-4" />
                                    <small className="text-muted d-flex justify-content-center">
                                        Appelez-nous pour obtenir plus de détails
                                    </small>

                                    <a href={"tel:+33627356295"} className="w-100 btn btn-lg btn-outline-primary pp-button-home-level">
                                        <i className="bi bi-phone primary-color" style={{ fontSize: '30px' }}></i>
                                        &nbsp; 06 27 35 62 95
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg item-hidden-992">
                                <img className="rounded-lg-3 image_logo_home" src={englishImage} alt="" height="550"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container py-4">
                    <h2 className="text-center mb-4">Ce que disent nos étudiants</h2>
                    <div className="row">
                        {[1, 2, 3, 4].map((item, index) => (
                            <div key={index} className="col-md-6 col-lg-3 d-flex align-items-stretch">
                                <div className="card mb-4 shadow-sm">
                                    {/*<img src={`../images/student${item}.jpg`} className="card-img-top" alt="Student" /> */}
                                    <i className="bi bi-person-circle" style={{fontSize: '4rem'}}></i>
                                    <div className="card-body">
                                        <h5 className="card-title">Jean Dupont</h5>
                                        <h6 className="text-muted">Mars 2024</h6>
                                        <p className="card-text">
                                            <i className="bi bi-star-fill text-warning"></i>
                                            <i className="bi bi-star-fill text-warning"></i>
                                            <i className="bi bi-star-fill text-warning"></i>
                                            <i className="bi bi-star-fill text-warning"></i>
                                            <i className="bi bi-star-half text-warning"></i>
                                        </p>
                                        <p><em>
                                            "Les cours d'anglais avec talkeasy ont été une expérience incroyable. Les méthodes d'enseignement personnalisées m'ont vraiment aidé à améliorer mon niveau."
                                        </em></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


                <div className={"row g-3 text-center div-circles"}>
                    <div className="card" style={{width: '18rem'}}>
                        <i className="bi bi-webcam  primary-color" style={{fontSize: '50px'}}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Cours 100% en ligne
                            </p>
                        </div>
                    </div>
                    <div className="card" style={{width: '18rem'}}>
                        <i className="bi bi-person-check-fill primary-color" style={{fontSize: '50px'}}></i>

                        <div className="card-body">
                            <p className="card-text">Suivie personnalisé pour chaque élève</p>
                        </div>
                    </div>
                    <div className="card" style={{width: '18rem'}}>
                        <i className="bi bi-translate primary-color" style={{fontSize: '50px'}}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Amèliorez significativement votre niveau oral et écrit
                            </p>
                        </div>
                    </div>
                    <div className="py-4 container-homepage">
                        <div className={"container   "}>
                            <h2>Explorez le Monde des Langues Étrangères en Ligne avec Nos Professeurs Particuliers</h2>
                            <p>
                                Bienvenue chez <Link to={"/"}>talkeasy</Link>, votre plateforme d'apprentissage des langues étrangères en ligne où l'expérience est facile, amusante et abordable.
                                Chez talkeasy, nous croyons en l'unicité de chaque étudiant, c'est pourquoi nous développons une approche personnalisée pour chaque élève.
                                Prêt à explorer le monde des langues ? Choisissez parmi nos <Link to={"/tarif"}>cours individuels</Link> ou nos <Link to={"/tarif"}>cours en groupe</Link>
                                et embarquez pour une aventure linguistique enrichissante !
                            </p>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(HomeComponent);