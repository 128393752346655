import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class ProductService {

    create(data) {
        return axios.post(Addr.apiAddress() + "/api/lesson/create", {
                datetime : data.datetime,
                lesson_comment: data.lesson_comment,
                state: data.state,
                teacherId: data.teacherId,
                userId: data.userId
            },
            {headers: authHeader()});
    }

    createByUser(datetime) {
        return axios.post(Addr.apiAddress() + "/api/lesson/createByUser", {
                datetime: datetime,
            },
            {headers: authHeader()});
    }

    async getAllData(){
        return axios.post(Addr.apiAddress() + "/api/lesson/get",{},{headers: authHeader()});
    }

    setDataById(id, data){
        return axios.post(Addr.apiAddress() + "/api/lesson/modify", {
                id: data.id,
                datetime : data.datetime,
                lesson_comment: data.lesson_comment,
                state: data.state,
                teacherId: data.teacherId,
                userId: data.userId
            },
            {headers: authHeader()});
    }

    getDataById(id){
        return axios.post(Addr.apiAddress() + "/api/lesson/id", {
                id : id
            },
            {headers: authHeader()});
    }
    delete(id){
        return axios.post(Addr.apiAddress() + "/api/lesson/delete", {
                id : id
            },
            {headers: authHeader()});
    }

    async getByUserId(){
        return axios.post(Addr.apiAddress() + "/api/lesson/getByUserId", {},{headers: authHeader()});
    }
    async cancelLesson(id){
        return axios.post(Addr.apiAddress() + "/api/lesson/cancelLesson", {id: id},{headers: authHeader()});
    }

}
export default new ProductService();
