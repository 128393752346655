import React, {Component} from 'react';
import '../styles/home.scss';
import Logo from '../images/logo_pp.svg'
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

class NoMatchComponent extends Component {

    componentDidMount() {
        document.title = '404 Page introuvable - talkeasy.fr'
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>404 Page introuvable - talkeasy.fr</title>
                    <meta name="description" content="Cours particuliers des langues étrangers avec talkeasy.fr.
                    L'apprentissage des langues étrangers en ligne est encore plus facile et abordable avec talkeasy.fr
                Nous nous adaptons à vous et nous dévélopons pour chaque élève une approche unique.
                Nous vous proposons des cours individuels et les cours en groupe"/>
                </MetaTags>
                <div className="px-4 py-5 my-5 text-center">
                    <img className="d-block mx-auto mb-4" src={Logo} alt="" width="200" height="200"/>
                        <h1 className="display-5 fw-bold">404 - destination introuvable</h1>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4">
                                Oupsi..
                            </p>

                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <Link type="button" className="btn btn-primary btn-lg px-4 gap-3"  to={"/"}>
                                    Revenir à l'accueil
                                </Link>
                            </div>
                        </div>
                </div>
            </>
        )
    }
}

export default NoMatchComponent;