import React, {Component} from "react";
import Logo from '../images/logo_pp.svg'
import SaleService from '../services/sale.service'
import QuoteService from '../services/quote.service'
import UserService from '../services/user.service';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import RevolutCheckout from "@revolut/checkout"

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Ce champ est obligatoire.
            </div>
        );
    }
};

class Checkout extends Component {
    ENV

    constructor(props) {
        super(props);

        this.handleSale = this.handleSale.bind(this);
        this.onChangeInvoice_first_name = this.onChangeInvoice_first_name.bind(this);
        this.onChangeInvoice_last_name = this.onChangeInvoice_last_name.bind(this);
        this.onChangeInvoice_address_1 = this.onChangeInvoice_address_1.bind(this);
        this.onChangeInvoice_address_2 = this.onChangeInvoice_address_2.bind(this);
        this.onChangeInvoice_country = this.onChangeInvoice_country.bind(this);
        this.onChangeInvoice_zipcode = this.onChangeInvoice_zipcode.bind(this);
        this.onChangeInvoice_city = this.onChangeInvoice_city.bind(this);
        this.handleCheckAddress = this.handleCheckAddress.bind(this);

        this.state = {
            quote: undefined,
            invoice_first_name: "",
            invoice_last_name: "",
            invoice_address_1: "",
            invoice_address_2: "",
            invoice_country: "",
            invoice_zipcode: "",
            invoice_city: "",
            successful: false,
            message: ""
        }
        this.ENV = 'prod'
    }

    async componentDidMount() {
        let connected = await UserService.isConnected()
        if (!connected) {
            this.props.history.push("/");
        }
        let quote = null;
        try {
            quote = (await QuoteService.getDataById())
        } catch (e) {
            await this.props.history.push("/");
        }
        let user = (await UserService.getMyProfile()).data

        this.setState({
            quote: quote.data,
            user: user,
            invoice_first_name: user.first_name,
            invoice_last_name: user.last_name,
        })

        let env = (await SaleService.getEnv()).data;
        if (env === 'stage' || env === 'prod') {
            this.ENV = env
        }
    }


    onChangeInvoice_first_name(e) {
        this.setState({
            invoice_first_name: e.target.value
        });
    }

    onChangeInvoice_last_name(e) {
        this.setState({
            invoice_last_name: e.target.value
        });
    }

    onChangeInvoice_address_1(e) {
        this.setState({
            invoice_address_1: e.target.value
        });
    }

    onChangeInvoice_address_2(e) {
        this.setState({
            invoice_address_2: e.target.value
        });
    }

    onChangeInvoice_country(e) {
        this.setState({
            invoice_country: e.target.value
        });
    }

    onChangeInvoice_zipcode(e) {
        this.setState({
            invoice_zipcode: e.target.value
        });
    }

    onChangeInvoice_city(e) {
        this.setState({
            invoice_city: e.target.value
        });
    }

    handleSale(e) {
        e.preventDefault();


    }

    async handleCheckAddress() {
        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            this.setState({
                successful: true
            })

            let payment = (await SaleService.createPayment(
                this.state.invoice_first_name,
                this.state.invoice_last_name,
                this.state.invoice_address_1,
                this.state.invoice_address_2,
                this.state.invoice_country,
                this.state.invoice_zipcode,
                this.state.invoice_city,
                'FR'
            )).data;

            let public_id = payment.public_id;

            if (typeof public_id !== undefined) {
                let instanceEnv = 'prod';

                if (this.ENV === 'stage') {
                    instanceEnv = 'sandbox';
                }
                let instance = await RevolutCheckout(public_id, instanceEnv);
                instance.setDefaultLocale('fr')
                let that = this
                instance.payWithPopup({
                    target: document.getElementById('revolut-pay'),
                    phone: this.state.user.phone,
                    email: this.state.user.email,
                    billingAddress: {
                        countryCode: this.state.invoice_country,
                        city: this.state.invoice_city,
                        streetLine1: this.state.invoice_address_1,
                        streetLine2: this.state.invoice_address_2,
                        postcode: this.state.invoice_zipcode,
                    },
                    async onSuccess() {
                        SaleService.updateStatus(payment.id, payment.merchant_order_ext_ref)
                        await UserService.getRefreshMyProfile()
                        that.props.history.push("/thankyou");
                    },
                    onCancel() {
                        SaleService.updateStatus(payment.id, payment.merchant_order_ext_ref)
                        that.setState({
                            successful: false
                        })
                    },
                    onError(error) {
                        SaleService.updateStatus(payment.id, payment.merchant_order_ext_ref)
                        that.setState({
                            successful: false
                        })
                    }
                })
            }
        }
    }


    render() {
        return (
            <>
                <div className={"container"}>
                    <div className="py-5 text-center">
                        <img className="d-block mx-auto mb-4" src={Logo} alt="" width="72"
                             height="57"/>
                        <h2>Récaputilatif de votre commande</h2>
                        <p className="lead">
                            Une fois la payement réalisé, vous pouvez réserver votre premier cours.
                        </p>
                    </div>

                    <div className="row g-5">
                        <div className="col-md-5 col-lg-4 order-md-last">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-primary">Votre panier</span>
                                <span className="badge bg-primary rounded-pill">1</span>
                            </h4>
                            {this.state.quote !== undefined ?
                                <ul className="list-group mb-3">
                                    {
                                        this.state.quote.products.map((product) => (
                                            <li className="list-group-item d-flex justify-content-between lh-sm"
                                                key={product.id}>
                                                <div>
                                                    <h6 className="my-0">{product.name}</h6>
                                                    <small className="text-muted">{product.price_hourly}€/heure</small>
                                                </div>
                                                <span className="text-muted">{product.price_after_tax}€</span>
                                            </li>
                                        ))
                                    }
                                    <li className="list-group-item d-flex justify-content-between">
                                        <span>Total (Euros)</span>
                                        <strong>{this.state.quote.total_after_tax}€</strong>
                                    </li>
                                </ul>
                                : null
                            }
                        </div>
                        <div className="col-md-7 col-lg-8">

                            <Form
                                onSubmit={this.handleSale}
                                ref={c => {
                                    this.form = c;
                                }} className="need-validation"
                            >
                                {!this.state.successful &&
                                <>
                                    <h4 className="mb-3">Adrresse de facturation</h4>
                                    <div className="row g-3">


                                        <div className="col-sm-6">
                                            <label htmlFor="firstName">Prénom</label>
                                            <Input
                                                id="invoice_first_name"
                                                type="text"
                                                className="form-control"
                                                name="firstName"
                                                value={this.state.invoice_first_name}
                                                onChange={this.onChangeInvoice_first_name}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="col-sm-6">
                                            <label htmlFor="lastName">Nom</label>
                                            <Input
                                                id="invoice_last_name"
                                                type="text"
                                                className="form-control"
                                                name="lastName"
                                                value={this.state.invoice_last_name}
                                                onChange={this.onChangeInvoice_last_name}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="address" className="form-label">Votre adresse</label>
                                            <Input
                                                id="invoice_address_1"
                                                type="text"
                                                className="form-control"
                                                placeholder="Adresse"
                                                name="billing_address_1"
                                                value={this.state.invoice_address_1}
                                                onChange={this.onChangeInvoice_address_1}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="address" className="form-label">Complement d'adresse</label>
                                            <Input
                                                id="invoice_address_2"
                                                type="text"
                                                className="form-control"
                                                placeholder="Complement d'adresse"
                                                name="billing_address_2"
                                                value={this.state.invoice_address_2}
                                                onChange={this.onChangeInvoice_address_2}
                                            />
                                        </div>

                                        <div className="col-md-5">
                                            <label htmlFor="country" className="form-label">Pays</label>
                                            <select required className="form-select" id="country"
                                                    onChange={this.onChangeInvoice_country}
                                                    value={this.state.invoice_country}>
                                                <option value="" disabled>Choisir...</option>
                                                <option value={'FR'}>France</option>
                                                <option value={'MO'}>Monaco</option>
                                            </select>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="zip" className="form-label">Code postale</label>
                                            <Input
                                                id="invoice_zipcode"
                                                type="text"
                                                className="form-control"
                                                name="invoice_zipcode"
                                                value={this.state.invoice_zipcode}
                                                onChange={this.onChangeInvoice_zipcode}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="city" className="form-label">Ville</label>
                                            <Input
                                                id="invoice_city"
                                                type="text"
                                                className="form-control"
                                                name="invoice_city"
                                                value={this.state.invoice_city}
                                                onChange={this.onChangeInvoice_city}
                                                validations={[required]}
                                            />
                                        </div>

                                    </div>

                                    <hr className="my-4"/>

                                    <button className="w-100 btn btn-primary btn-lg" type="submit"
                                            onClick={this.handleCheckAddress}>
                                        Suivant
                                    </button>
                                </>
                                }

                                <div id='revolut-pay'></div>


                                {this.state.message && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                this.state.successful
                                                    ? "alert alert-success"
                                                    : "alert alert-danger"
                                            }
                                            role="alert"
                                        >
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton
                                    style={{display: "none"}}
                                    ref={c => {
                                        this.checkBtn = c;
                                    }}
                                />
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Checkout;