import React, {Component} from "react";
import UserService from "../../services/user.service";


export default class Lesson_dashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized: false,
        }
    }


    async componentDidMount() {
        UserService.checkConnected();
        let roles = await UserService.getRoles();
        if (roles.data.includes('admin')) {
            this.setState({
                authorized: true
            })
        }
    }


    render() {
        return (
            <>
                Lesson
            </>
        );
    }
}