import React from "react"


class apiAddr extends React.Component {

    static apiAddress() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return window.location.origin + ':8080';
        } else {
            return window.location.origin;
        }
    }
}
export default apiAddr;