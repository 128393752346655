
import React, {Component} from 'react';
import '../styles/home.scss';
import Logo from '../images/logo_pp.svg'
import {Link} from "react-router-dom";

class ThankYouComponent extends Component {

    componentDidMount() {
        document.title = 'Merci pour votre achat - talkeasy.fr'
    }

    render() {
        return (
            <>
                <div className="px-4 py-5 my-5 text-center">
                    <img className="d-block mx-auto mb-4" src={Logo} alt="" width="72"
                         height="57"/>
                        <h1 className="display-5 fw-bold">Merci pour votre achat</h1>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4">
                                Désormais vous pouver réserver vos cours. Vous pouvez reserver en ligne ou simplement
                                nous appeler.
                            </p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">

                                <Link to={"/lessons"}>
                                    <button type="button" className="btn btn-primary btn-lg px-4 gap-3">Réserver</button>
                                </Link>
                                <a href={"tel:0627356295"}>
                                    <button type="button" className="btn btn-outline-primary btn-lg px-4 gap-3">06 27 35 62 95</button>
                                </a>

                            </div>
                        </div>
                </div>
             </>
        )
    }
}

export default ThankYouComponent;