import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from 'react-select'
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import {withTranslation} from "react-i18next";
import UserService from "../services/user.service";
import LoaderComponent from "./loader.component";
import { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import MetaTags from "react-meta-tags";
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Ce champ est obligatoire.
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vpassword = value => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};



class Register extends Component {
    options
    optionsArray
    subOptions
    possibleOptions
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeFirst_name = this.onChangeFirst_name.bind(this);
        this.onChangeLast_name = this.onChangeLast_name.bind(this);
        this.onChangeDate_birthday = this.onChangeDate_birthday.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeLevel = this.onChangeLevel.bind(this);
        this.onChangeSubLevel = this.onChangeSubLevel.bind(this);

        this.possibleOptions = ['primary', 'college','lycee'];
        this.state = {
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            date_birthday: "",
            phone: "",
            level: this.props.match.params.type,
            successful: false,
            message: "",
            options: [],
            subSelectExists: this.possibleOptions.includes(this.props.match.params.type),
            sub_level : '',
            loading: false,
            phoneSuccessful: false,
            phoneMessage: "",
        };
        this.options = [
            { value: 'primary', label: 'Ecole primaire' },
            { value: 'college', label: 'Collège' },
            { value: 'lycee', label: 'Lycée' },
            { value: 'adult', label: 'Adulte' }
        ]
        this.optionsArray = {
            'primary':  {value: 'primary', label: 'Ecole primaire'},
            'college': {value: 'college', label: 'Collège'},
            'lycee': {value: 'lycee', label: 'Lycée'},
            'adult': {value: 'adult', label: 'Adulte'}
        }
        this.subOptions = {
            'primary':  [
                { value: 'cp', label: 'CP' },
                { value: 'ce1', label: 'CE1' },
                { value: 'ce2', label: 'CE2' },
                { value: 'cm1', label: 'CM1' },
                { value: 'cm2', label: 'CM2' }
            ],
            'college': [
                { value: '6', label: '6ème' },
                { value: '5', label: '5ème' },
                { value: '4', label: '4ème' },
                { value: '3', label: '3ème' }
            ],
            'lycee': [
                { value: '2', label: '2nd' },
                { value: '1', label: '1ère' },
                { value: '0', label: 'Terminal' }
            ]
        }

    }
    async componentDidMount() {
        document.title = 'Créer un compte - talkeasy.fr'
        let connected = await UserService.isConnected()
        if (connected) {
            this.props.history.push("/");
        }
    }


    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangeFirst_name(e) {
        this.setState({
            first_name: e.target.value
        });
    }

    onChangeLast_name(e) {
        this.setState({
            last_name: e.target.value
        });
    }

    onChangeDate_birthday(e) {
        this.setState({
            date_birthday: e.target.value
        });
    }

    onChangePhone(e) {
        this.setState({
            phone: e.target.value
        });
    }

    onChangeLevel(e) {
        this.setState({
            level: e.value
        });

         if(this.possibleOptions.includes(e.value)){
             this.setState({
                 subSelectExists : true
             })
         }else{
             this.setState({
                 subSelectExists : false
             })
         }
    }

    onChangeSubLevel(e) {
        this.setState({
            sub_level: e.value
        });
    }

    handleRegister(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0 && isValidPhoneNumber(this.state.phone)) {
            this.setState({
                loading: true
            })
            AuthService.register(
                '',
                this.state.email,
                this.state.password,
                this.state.first_name,
                this.state.last_name,
                this.state.date_birthday,
                this.state.phone,
                this.state.level,
                this.state.sub_level
            ).then(
                response => {
                    AuthService.login(this.state.email, this.state.password).then(
                        () => {
                            this.props.history.push('/')
                            window.location.reload();
                        }
                    );

                    this.setState({
                        message: response.data.message,
                        successful: true,
                        loading: false
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        successful: false,
                        message: resMessage,
                        loading: false
                    });
                }
            );
        }
        else{
            if(!isValidPhoneNumber(this.state.phone)){
                this.setState({
                    phoneSuccessful: false,
                    phoneMessage: "Numèro de téléphone invalide",
                    loading: false
                });
            }
        }
    }
    checkPhone(){

    }

    render() {
        const { t } = this.props;
        return (
            <>
                <MetaTags>
                    <title>Créer un compte - talkeasy.fr</title>
                    <meta name="description" content="talkeasy.fr - Créez un compte en ligne et réservez votre cours en quelques cliques seulement!"/>
                </MetaTags>
                <LoaderComponent
                    show={this.state.loading}/>
            <div className="col-md-12">
                <div className="card card-container">

                    <Form
                        onSubmit={this.handleRegister}
                        ref={c => {
                            this.form = c;
                        }} className="form-signin"
                    >

                        <h1 className="h3 mb-3 font-weight-normal">Créer un compte</h1>
                        <br/>
                        {!this.state.successful && (
                            <div>

                                <div className="form-group">
                                    <label htmlFor="level">{t("Votre niveau")}</label>

                                    <Select name={'level'} onChange={this.onChangeLevel} options={this.options} value={this.optionsArray[this.state.level]}/>
                                </div>

                                {this.state.subSelectExists ? (
                                    <div className="form-group">
                                        <label htmlFor="level">{t("Quelle est votre classe")}</label>
                                        <Select name={'sublevel'}  options={this.subOptions[this.state.level]} onChange={this.onChangeSubLevel}/>
                                    </div>
                                )
                                    :
                                    null
                                }

                                <div className="form-group">
                                    <label htmlFor="firstName">{t("Prénom")}</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        value={this.state.firstName}
                                        onChange={this.onChangeFirst_name}
                                        validations={[required]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="last_name">{t("Nom")}</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        value={this.state.last_name}
                                        onChange={this.onChangeLast_name}
                                        validations={[required]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">{t("Email")}</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChangeEmail}
                                        validations={[required, email]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">{t("Mot de passe")}</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChangePassword}
                                        validations={[required, vpassword]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="date_birthday">{t("Date de naissance")}</label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        name="date_birthday"
                                        value={this.state.date_birthday}
                                        onChange={this.onChangeDate_birthday}
                                        validations={[required]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">{t("Numéro de téléphone")}</label>
                                    <PhoneInput
                                        defaultCountry="FR"
                                        value={this.state.phone}
                                        onChange={phone => this.setState({ phone: phone, phoneMessage: '' })}
                                        required
                                    />
                                    {this.state.phoneMessage && (
                                    <div
                                        className={
                                            this.state.phoneSuccessful
                                                ? "alert alert-success"
                                                : "alert alert-danger"
                                        }
                                        role="alert"
                                    >
                                        {this.state.phoneMessage}
                                    </div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="w-100 btn btn-lg btn-primary pp-button-home-level">{t("Créer mon compte")}</button>
                                </div>

                            </div>
                        )}

                        {this.state.message && (
                            <div className="form-group">
                                <div
                                    className={
                                        this.state.successful
                                            ? "alert alert-success"
                                            : "alert alert-danger"
                                    }
                                    role="alert"
                                >
                                    {this.state.message}
                                </div>
                            </div>
                        )}
                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                                this.checkBtn = c;
                            }}
                        />
                    </Form>
                </div>
            </div>
            </>
        );
    }
}
export default withTranslation()(Register)