import React, {Component} from "react";
import UserService from "../../services/user.service";

export default class User_dashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            searchValue: ""
        }
    }


    async componentDidMount() {
        let users = await UserService.getAllUsers()
        this.setState({
            users: users.data
        })
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.searchValue !== this.props.searchValue) {

            let users = await UserService.getAllUsersByQuery(this.props.searchValue)
            await this.setState({
                searchValue: this.props.searchValue,
                users: users.data
            })

        }
    }

    renderUsers(user){
        return (
            <>
                <div className="card text-center">
                    <div className="card-header">
                        {user.id}
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{user.first_name +' ' + user.last_name}</h5>
                        <p className="card-text">Email : {user.email}</p>
                        <p className="card-text">Phone :  {user.phone}</p>
                        <p className="card-text">Birthday :  {user.date_birthday}</p>
                        <p className="card-text">Soldes : Cours groupés :  {parseInt(user.group_lessons_balance)}
                          &nbsp;  Cours individuels :{parseInt(user.lessons_balance)}
                        </p>
                    </div>
                    <div className="card-footer text-muted">
                        <div className=" gap-lg-2">
                            <button type="submit" href="#" className="btn btn-primary" onClick={()=>{if(window.confirm("Etes-vous sûr de changer le mdp de l'utilisateur?")){UserService.resetPassword(user.id)}}}>Reset MDP</button>
                            <button type="submit" href="#" className="btn btn-primary" onClick={async () => {
                                if (window.confirm("Etes-vous sûr de ajouter une heure a cet utilsateur?")) {
                                    try {
                                        await UserService.addHourIndividual(user.id)
                                        window.location.reload()
                                    } catch (error) {
                                        let message = (error.response &&
                                                error.response.data &&
                                                error.response.data.message) ||
                                            error.message ||
                                            error.toString();
                                        alert(message)
                                    }
                                }
                            }}>Ajouter 1 heure individuel</button>

                            <button type="submit" href="#" className="btn btn-primary" onClick={async () => {
                                if (window.confirm("Etes-vous sûr de ajouter une heure a cet utilsateur?")) {
                                    try {
                                        await UserService.addHourGroup(user.id)
                                        window.location.reload()
                                    } catch (error) {
                                        let message = (error.response &&
                                                error.response.data &&
                                                error.response.data.message) ||
                                            error.message ||
                                            error.toString();
                                        alert(message)
                                    }
                                }
                            }}>Ajouter 1 heure groupe</button>

                            <button type="submit" href="#" className="btn btn-danger" onClick={async () => {
                                if (window.confirm("Etes-vous sûr de supprimer l'utilisateur?")) {
                                    try {
                                        await UserService.delete(user.id)
                                    } catch (error) {
                                        let message = (error.response &&
                                                error.response.data &&
                                                error.response.data.message) ||
                                            error.message ||
                                            error.toString();
                                        alert(message)
                                    }
                                }
                            }}>Supprimer</button>


                        </div>
                    </div>
                </div>
            </>
        )
    }

     render() {
        return (
            <>
                {
                    this.state.users.map((user)=>(
                        this.renderUsers(user)
                    ))
                }
            </>
        );
    }
}