import React, {Component} from "react";
import UserService from "../../services/user.service";
import AdminNavbar from "./admin_navbar.component";
import '../../styles/admin.scss';
import AdminTable from "./admin_tables.component";


export default class AdminPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table: "undefined" !== typeof this.props.match.params.table ? this.props.match.params.table : '',
            authorized: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.table !== this.props.match.params.table) {
            this.setState({
                table: this.props.match.params.table
            })
        }
    }

    async componentDidMount() {
        document.title = 'Admin dashboard - talkeasy.fr'
        UserService.checkConnected();
        let roles = await UserService.getRoles();
        if (roles.data.includes('admin')) {
            this.setState({
                authorized: true
            })
        }
    }


    render() {
        return (
            <>
                {
                    this.state.authorized ?
                        <>
                            <div className="py-4 container-homepage">
                                <AdminNavbar/>
                                <div className={'container admin-content'}>
                                    <AdminTable table={this.state.table}/>
                                </div>
                            </div>
                        </>
                        :
                        null
                }
            </>
        );
    }
}
