import React, {Component} from "react";

import {withTranslation} from "react-i18next";
import LessonService from "../../services/lesson.service";
import UserService from "../../services/user.service";
import {Link} from "react-router-dom";
import Modal from "../modal.component"

class LessonList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successful: false,
            message: "",
            profile: [],
            lessons: [],
            alert: "true",
            alertSuccess: "",
            alertMessage: "",
            showModal: false,
            modalName: "Information importante",
            modalContent: ""
        };
        this.showModal = this.showModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.cancelLesson = this.cancelLesson.bind(this)
    }

    showModal() {
        this.setState({
            showModal: true
        })
    }

    closeModal() {
        this.setState({
            showModal: false
        })
        window.location.reload();
    }

    async componentDidMount() {
        let connected = await UserService.isConnected()
        if (!connected) {
            this.props.history.push("/");
        }
        let lessons = (await LessonService.getByUserId()).data;
        let profile = (await UserService.getRefreshMyProfile()).data
        if (lessons !== null) {
            this.setState({
                lessons: lessons,
                profile: profile
            })
        }
    }
    async cancelLesson(id){
        try {
            await LessonService.cancelLesson(id);
            await this.setState({
                alert: true,
                alertSuccess: "success",
                alertMessage: "Le cours a été annulé"
            })
            this.showModal()
        } catch (e) {
            await this.setState({
                alert: true,
                alertSuccess: "danger",
                alertMessage: "Une erreur est survenue"
            })
            this.showModal()
        }
    }


    render() {
        return (
            <>
                <Modal
                    alertSuccess={this.state.alertSuccess}
                    alertMessage={this.state.alertMessage}
                    modalName={this.state.modalName}
                    alert={this.state.alert}
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    modalContent={this.state.modalContent}

                />
                <div className="py-4 container container-homepage ">
                    <div className="col-md-12">
                        <div className="card card-container">
                            <div className="form-signin">
                                <h3 className="mb-1">Votre solde : {this.state.profile.lessons_balance === null ? 0 :
                                    this.state.profile.lessons_balance} heures</h3>
                                {this.state.profile.lessons_balance === null ?
                                    <Link to={"/tarif"} className="w-100 btn btn-lg btn-primary pp-button-home-level">
                                        Acheter un pack
                                    </Link>
                                    :
                                    <Link to={"/book"} className="w-100 btn btn-lg btn-primary pp-button-home-level">
                                        Réserver en ligne
                                    </Link>
                                }

                            </div>


                            <div className="list-group">
                                {this.state.lessons.length < 1 ?
                                    <a href="/lessons" className="list-group-item list-group-item-action " aria-current="true">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">Vous n'avez aucune réservation disponible</h5>
                                        </div>
                                        <p className="mb-1">Ici vont apparaitre vos futurs réservations</p>
                                    </a>
                                    :

                                    this.state.lessons.map((data) => (
                                        <div className="list-group-item list-group-item-action"
                                           aria-current="true">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">Reservation No: {data.id}</h5>
                                                <small>{new Date(data.datetime).toLocaleDateString() + " "
                                                + new Date(data.datetime).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</small>
                                            </div>
                                            <p className="mb-1">
                                                {data.state === "pending" ? "En attente d'approbation" :
                                                    data.state === "approved" ? "Accepté" : "Rejeté ou annulé"}
                                            </p>
                                            <small className="btn btn-danger" onClick={()=>{this.cancelLesson(data.id)}}>Annuler</small>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(LessonList)