import React, {Component} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import {withTranslation} from "react-i18next";
import UserService from "../services/user.service";
import LoaderComponent from "./loader.component";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {isValidPhoneNumber} from "react-phone-number-input";
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Ce champ est obligatoire.
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vpassword = value => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                Mot de passe doit contenir entre 6 et 40 caractères.
            </div>
        );
    }
};

const vpasswordnew = value => {
    if ((value.length < 6 || value.length > 40) && value.length > 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Mot de passe doit contenir entre 6 et 40 caractères.
            </div>
        );
    }
};


class Profile extends Component {
    options
    optionsArray
    subOptions
    possibleOptions

    constructor(props) {
        super(props);
        this.handleMofify = this.handleMofify.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeFirst_name = this.onChangeFirst_name.bind(this);
        this.onChangeLast_name = this.onChangeLast_name.bind(this);
        this.onChangeDate_birthday = this.onChangeDate_birthday.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeLevel = this.onChangeLevel.bind(this);
        this.onChangeSubLevel = this.onChangeSubLevel.bind(this);
        this.onChangeConfirmNewPassword = this.onChangeConfirmNewPassword.bind(this);
        this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
        this.confirm = this.confirm.bind(this);

        this.possibleOptions = ['primary', 'college', 'lycee'];
        this.state = {
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            date_birthday: "",
            phone: "",
            level: this.props.match.params.type,
            successful: false,
            phoneSuccessful:false,
            phoneMessage: "",
            message: "",
            options: [],
            subSelectExists: this.possibleOptions.includes(this.props.match.params.type),
            sub_level: '',
            new_password: '',
            confirm_new_password: '',
            loading: false,
        };
        this.options = [
            {value: 'primary', label: 'Ecole primaire'},
            {value: 'college', label: 'Collège'},
            {value: 'lycee', label: 'Lycée'},
            {value: 'adult', label: 'Adulte'}
        ]
        this.optionsArray = {
            'primary': {value: 'primary', label: 'Ecole primaire'},
            'college': {value: 'college', label: 'Collège'},
            'lycee': {value: 'lycee', label: 'Lycée'},
            'adult': {value: 'adult', label: 'Adulte'}
        }
        this.subOptions = {
            'primary': [
                {value: 'cp', label: 'CP'},
                {value: 'ce1', label: 'CE1'},
                {value: 'ce2', label: 'CE2'},
                {value: 'cm1', label: 'CM1'},
                {value: 'cm2', label: 'CM2'}
            ],
            'college': [
                {value: '6', label: '6ème'},
                {value: '5', label: '5ème'},
                {value: '4', label: '4ème'},
                {value: '3', label: '3ème'}
            ],
            'lycee': [
                {value: '2', label: '2nd'},
                {value: '1', label: '1ère'},
                {value: '0', label: 'Terminal'}
            ]
        }

    }

    async componentDidMount() {
        try {
            let connected = await UserService.isConnected()
            if (!connected) {
                this.props.history.push("/");
            }
            let user = (await UserService.getRefreshMyProfile()).data;
            this.setState({
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                level: user.level,
                phone: user.phone,
                date_birthday: user.date_birthday !== null ? new Date(user.date_birthday).toISOString().split('T')[0] : "",
                subSelectExists: user.sub_level !== null,
                sub_level: user.sub_level,
            })
        } catch (e) {
            this.props.history.push("/");
        }

    }

    confirm(value) {
        if (value !== this.state.new_password) {
            return (
                <div className="alert alert-danger" role="alert">
                    Les mot de passses ne correspondent pas
                </div>
            );
        }
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangeNewPassword(e) {
        this.setState({
            new_password: e.target.value
        });
    }

    onChangeConfirmNewPassword(e) {
        this.setState({
            confirm_new_password: e.target.value
        });
    }


    onChangeFirst_name(e) {
        this.setState({
            first_name: e.target.value
        });
    }

    onChangeLast_name(e) {
        this.setState({
            last_name: e.target.value
        });
    }

    onChangeDate_birthday(e) {
        this.setState({
            date_birthday: e.target.value
        });
    }

    onChangePhone(e) {
        this.setState({
            phone: e
        });
    }

    onChangeLevel(e) {
        this.setState({
            level: e.value
        });

        if (this.possibleOptions.includes(e.value)) {
            this.setState({
                subSelectExists: true
            })
        } else {
            this.setState({
                subSelectExists: false
            })
        }
    }

    onChangeSubLevel(e) {
        this.setState({
            sub_level: e.value
        });
    }



    async handleMofify(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0  && isValidPhoneNumber(this.state.phone)) {
            this.setState({
                loading: true
            })
            try {
                let response = await UserService.modify(
                    '',
                    this.state.email,
                    this.state.password,
                    this.state.new_password,
                    this.state.first_name,
                    this.state.last_name,
                    this.state.date_birthday,
                    this.state.phone
                );

                if (this.state.new_password !== "") {
                    UserService.getRefreshMyProfile()
                }

                this.setState({
                    message: response.data.message,
                    successful: true,
                    loading: true
                });
                let that = this
                setTimeout(function(){that.props.history.push("/")}  , 3000)

            } catch (error) {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    successful: false,
                    message: resMessage,
                    loading: false
                });
            }
        }
        else
        {
            if(!isValidPhoneNumber(this.state.phone)){
                this.setState({
                    phoneSuccessful: false,
                    phoneMessage: "Numèro de téléphone invalide",
                    loading: false
                });
            }
        }
    }



    render() {
        const {t} = this.props;
        return (
            <>
                <LoaderComponent
                    show={this.state.loading}/>
                <div className="col-md-12">
                    <div className="card card-container">

                        <Form
                            onSubmit={this.handleMofify}
                            ref={c => {
                                this.form = c;
                            }} className="form-signin"
                        >

                            <h1 className="h3 mb-3 font-weight-normal">Vos informations</h1>
                            <br/>

                            <div>

                                {/*  <div className="form-group">
                        <label htmlFor="level">{t("Votre niveau")}</label>

                        <Select name={'level'} onChange={this.onChangeLevel} options={this.options} value={this.optionsArray[this.state.level]}/>
                      </div>

                      {this.state.subSelectExists ? (
                              <div className="form-group">
                                <label htmlFor="level">{t("Quelle est votre classe")}</label>
                                <Select name={'sublevel'}  options={this.subOptions[this.state.level]} onChange={this.onChangeSubLevel} value={this.state.sub_level}/>
                              </div>
                          )
                          :
                          null
                      }*/}
                                <div className="form-group">
                                    <label htmlFor="email">{t("Email *")}</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={this.state.email || ""}
                                        onChange={this.onChangeEmail}
                                        validations={[required, email]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="firstName">{t("Prénom *")}</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        value={this.state.first_name || ""}
                                        onChange={this.onChangeFirst_name}
                                        validations={[required]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="last_name">{t("Nom *")}</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        value={this.state.last_name || ""}
                                        onChange={this.onChangeLast_name}
                                        validations={[required]}
                                    />
                                </div>



                                <div className="form-group">
                                    <label htmlFor="phone">{t("Numéro de téléphone *")}</label>
                                    <PhoneInput
                                        defaultCountry={'FR'}
                                        value={this.state.phone}
                                        onChange={phone => this.setState({ phone: phone, phoneMessage: '' })}
                                        required
                                    />
                                    {this.state.phoneMessage && (
                                    <div
                                        className={
                                            this.state.phoneSuccessful
                                                ? "alert alert-success"
                                                : "alert alert-danger"
                                        }
                                        role="alert"
                                    >
                                        {this.state.phoneMessage}
                                    </div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="date_birthday">{t("Date de naissance")}</label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        name="date_birthday"
                                        value={this.state.date_birthday || ""}
                                        onChange={this.onChangeDate_birthday}
                                        validations={[]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">{t("Mot de passe actuel *")}</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={this.state.password || ""}
                                        onChange={this.onChangePassword}
                                        validations={[required, vpassword]}
                                    />
                                </div>

                                <hr/>
                                <small className="text-muted d-flex justify-content-center">
                                    Changer le mot de passe
                                </small>
                                <hr/>

                                <div className="form-group">
                                    <label htmlFor="phone">{t("Nouveau mot de passe")}</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="new_password"
                                        value={this.state.new_password || ""}
                                        onChange={this.onChangeNewPassword}
                                        validations={[vpasswordnew]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">{t("Confirmer le nouveau mot de passe")}</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="confirm_new_password"
                                        value={this.state.confirm_new_password || ""}
                                        onChange={this.onChangeConfirmNewPassword}
                                        validations={[vpasswordnew, this.confirm]}
                                    />
                                </div>




                                <div className="form-group">
                                    <button type="submit"
                                        className="w-100 btn btn-lg btn-primary pp-button-home-level">{t("Valider le changement")}</button>
                                </div>

                            </div>

                            {this.state.message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            this.state.successful
                                                ? "alert alert-success"
                                                : "alert alert-danger"
                                        }
                                        role="alert"
                                    >
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                            <CheckButton
                                style={{display: "none"}}
                                ref={c => {
                                    this.checkBtn = c;
                                }}
                            />
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(Profile)