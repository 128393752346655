import React, {Component} from "react";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";
import '../styles/footer.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                showAdminBoard: user.roles.includes("ROLE_ADMIN"),
            });
        }
    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <div className="container">
                    <footer className="py-3 my-4">
                        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                            <li className="nav-item">
                                <Link to={"/"} className="nav-link px-2 text-muted"> {t('Accueil')}</Link>
                            </li>
                            {/*<li className="nav-item">
                                <Link className="nav-link px-2 text-muted"
                                      to={"/tarif"}>{t('Nos formules')}</Link>
                            </li>*/}
                          {/*  <li className="nav-item">
                                <Link className="nav-link px-2 text-muted"
                                      to={"/cpf"}>{t('Qu\'est-ce-que le CPF ?')}</Link>
                            </li>*/}
                            {/*<li className="nav-item">
                                <Link to={"/about-us"}
                                      className="nav-link px-2 text-muted"> {t('Qui sommes nous')}</Link>
                            </li>*/}
                            <li className="nav-item">
                                <Link to={"/contact-us"}
                                      className="nav-link px-2 text-muted"> {t('Nous contacter')}</Link>
                            </li>
                        </ul>
                        <p className="text-center text-muted">&copy; 2023 talkeasy.fr
                            {/*v.{process.env.REACT_APP_VERSION}*/}</p>
                    </footer>
                </div>
            </>
        );
    }
}

export default withTranslation()(Footer);




