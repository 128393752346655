import React, {Component} from 'react';
import '../styles/cpf.scss';
import {withTranslation} from "react-i18next";
import englishImage from '../images/logo_pp.svg';
import Pavel from '../images/pavel.jpg'
import Olga from '../images/olga.jpg'
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

class AboutUs extends Component {

    componentDidMount() {
        document.title = 'Qui sommes nous - talkeasy.fr'
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Qui sommes nous - talkeasy.fr</title>
                    <meta name="description" content="Cours de langues étrangers avec talkeasy.fr.
                    L'apprentissage de langues étrangers en ligne est encore plus facile et abordable avec talkeasy.fr
                Nous nous adaptons à vous et nous dévélopons pour chaque élève une approche unique.
                Nous vous proposons des cours individuels et les cours en groupe"/>
                </MetaTags>
                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div className="col-10 col-sm-8 col-lg-6">
                            <img src={englishImage} className="d-block mx-lg-auto img-fluid"
                                 alt="Bootstrap Themes" width="200px"  loading="lazy"/>
                        </div>

                        <div className="col-md-6">
                            <h2>Qui sommes nous? </h2>
                            <p>
                                Chez <Link to={"/"}><b>talkeasy.fr</b></Link> - votre apprentissage d'anglais, italien, espagnol et bien d'autres langues est plus facile et abordable.
                                Nous nous adaptons à vous et nous dévélopons pour chaque élève une approche unique.
                                Nous vous proposons des cours individuels et les cours en groupe par des professeurs certifiés.
                            </p>
                            <ul className="icon-list">
                                <li>
                                    <a href="#olga" rel="noopener">
                                        Olga CARSAC
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <hr className="featurette-divider" />

                        <div className="row row-cols-1 row-cols-md-3 g-4 align-content-center justify-content-center">

                            <div className="col" id={'olga'}>
                                <div className="card">
                                    <img src={Olga} className="card-img-top" alt="Photo Olga"/>
                                    <div className="card-body">
                                        <h5 className="card-title">Olga CARSAC</h5>
                                        <p className="card-text">
                                            Professeur d'anglais depuis 1990, certifié par Cambridge University.
                                        </p>
                                    </div>
                                </div>
                            </div>

                           {/* <div className="col" id={'pavel'}>
                                <div className="card">
                                    <img src={Pavel} className="card-img-top" alt="Photo Pavel"/>
                                    <div className="card-body">
                                        <h5 className="card-title">Pavel SMIRNOV</h5>
                                        <p className="card-text">
                                            Ingénieur DevOps, s'occupe du fonctionnement du site talkeasy.fr

                                        </p>
                                        <a href={"https://www.linkedin.com/in/pavel-smirnov/"}>linkedin.com/in/pavel-smirnov/</a>
                                    </div>
                                </div>
                            </div>*/}
                        </div>


                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(AboutUs);