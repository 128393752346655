import React, {Component} from "react";
import UserService from "../../services/user.service";
import '../../styles/admin.scss';
import {withTranslation} from "react-i18next";
import {Route} from "react-router-dom";
import Service from "../../services/service";

class AdminTableList extends Component {
    services
    fields
    constructor(props) {
        super(props);
        this.onClickRow = this.onClickRow.bind(this)
        this.handleClickCreate = this.handleClickCreate.bind(this)
        UserService.checkConnected();
        UserService.getRoles().then(response => {
            if (!response.data.includes('admin')) {
                window.location.href = window.location.protocol + "//" + window.location.hostname
            }
        })

        this.state = {
            table: this.props.table,
            response : []
        };


        this.fields = this.props.fields

    }

    componentDidMount() {
        this.initialiseComponent();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.table !== this.props.table) {
            await this.setState({
                table: this.props.table
            })
            this.initialiseComponent();
        }
    }

    initialiseComponent(){
        if("undefined" !== typeof this.state.table)
        {
            let CurrentService = new Service(this.state.table)
            if('undefined' !== typeof CurrentService){
                let data = {}
                data[this.props.tableRowName] = this.props.tableRowId
                CurrentService.getByData(data).then(response=> {
                    this.setState({
                        response : response.data
                    })
                })
            }
        }
    }

    onClickRow(history, id){
        history.push("/admin/create/" + this.state.table + '/' + id)
        window.location.reload();
    }

    handleClickCreate(){
        this.props.history.push("/admin/create/"+ this.state.table + "?"+this.props.tableRowName + "=" + this.props.tableRowId)
        window.location.reload();
    }

    render() {
        const {t} = this.props;
        return (
            <>
                {"undefined" !== typeof this.fields ?
                    <div className={'container admin-content'}>
                            <button className="btn btn-outline-dark" type="button" onClick={this.handleClickCreate}>Créer {this.state.table}</button>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                {
                                    this.fields.map((element) => (
                                        <th key={element} scope="col">{t(element)}</th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.response.map((data) => (
                                    <Route key={data.id} render={({history}) => (
                                        <tr onClick={() => {this.onClickRow(history, data.id)}}>
                                            {
                                                this.fields.map((element) => (
                                                    <td key={element}>{
                                                        typeof data[element] !== "undefined" && (element === 'datetime' || element === 'createdAt' || element === 'updatedAt')    ?
                                                            new Date(data[element]).toLocaleDateString() + " " + new Date(data[element]).toLocaleTimeString() : (data[element])
                                                    }</td>
                                                ))
                                            }
                                        </tr>
                                    )}/>
                                ))
                            }

                            </tbody>

                        </table>
                    </div>
                    :
                    null
                }
            </>

        );
    }
}
export default withTranslation()(AdminTableList)