import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class ProductService {

    create(data) {
        return axios.post(Addr.apiAddress() + "/api/product/create", {
                name: data.name,
                description: data.description,
                short_description: data.short_description,
                price_before_tax: data.price_before_tax,
                price_after_tax: data.price_after_tax,
                on_sale: data.on_sale,
                rank: data.rank,
                price_hourly: data.price_hourly,
                hours_qty: data.hours_qty,
                enabled: data.enabled,
                is_group: data.is_group,
            },
            {headers: authHeader()});
    }

    async getAllData() {
        return axios.post(Addr.apiAddress() + "/api/product/get", {}, {headers: authHeader()});
    }

    setDataById(id, data) {
        return axios.post(Addr.apiAddress() + "/api/product/modify", {
                id: id,
                name: data.name,
                description: data.description,
                short_description: data.short_description,
                price_before_tax: data.price_before_tax,
                price_after_tax: data.price_after_tax,
                on_sale: data.on_sale,
                rank: data.rank,
                price_hourly: data.price_hourly,
                hours_qty: data.hours_qty,
                enabled: data.enabled,
                is_group: data.is_group,
            },
            {headers: authHeader()});
    }

    getDataById(id) {
        return axios.post(Addr.apiAddress() + "/api/product/id", {
                id: id
            },
            {headers: authHeader()});

    }

    delete(id) {
        return axios.post(Addr.apiAddress() + "/api/product/delete", {
                id: id
            },
            {headers: authHeader()});
    }
}

export default new ProductService();
