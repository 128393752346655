import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import UserService from "../../services/user.service";
import LessonService from "../../services/lesson.service";
import {withTranslation} from "react-i18next";


const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Ce champ est obligatoire.
            </div>
        );
    }
};





class Booking extends Component {

    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeDatetime = this.onChangeDatetime.bind(this);

        this.state = {
            datetime: "",
            successful: false,
            message: "",

        };


    }
    async componentDidMount() {
        let connected = await UserService.isConnected()
        if (!connected) {
            this.props.history.push("/");
        }
    }


    onChangeDatetime(e) {
        this.setState({
            datetime: e.target.value
        });
    }








    handleRegister(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            LessonService.createByUser(
                this.state.datetime,
                this.state.state,
            ).then(
                response => {
                    this.props.history.push("/lessons/");
                    window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        successful: false,
                        message: resMessage
                    });
                }
            );
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="col-md-12">
                <div className="card card-container">

                    <Form
                        onSubmit={this.handleRegister}
                        ref={c => {
                            this.form = c;
                        }} className="form-signin"
                    >

                        <h1 className="h3 mb-3 font-weight-normal">Créer une demande de cours</h1>
                        <br/>
                        {!this.state.successful && (
                            <div>

                                <div className="form-group">
                                    <label htmlFor="firstName">{t("Date et l'heure")}</label>
                                    <Input
                                        type="datetime-local"
                                        className="form-control"
                                        name="datetime"
                                        value={this.state.datetime}
                                        onChange={this.onChangeDatetime}
                                        validations={[required]}
                                        min={new Date().toISOString()}
                                    />
                                </div>
                                <hr/>
                                <small>
                                    Vous pouvez annuler votre cours jusqu'à 24 heures avant le début du cours.
                                </small>
                                <hr/>
                                <div className="form-group">
                                    <button className="w-100 btn btn-lg btn-primary pp-button-home-level">{t("Valider ma demande")}</button>
                                </div>

                            </div>
                        )}

                        {this.state.message && (
                            <div className="form-group">
                                <div
                                    className={
                                        this.state.successful
                                            ? "alert alert-success"
                                            : "alert alert-danger"
                                    }
                                    role="alert"
                                >
                                    {this.state.message}
                                </div>
                            </div>
                        )}
                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                                this.checkBtn = c;
                            }}
                        />
                    </Form>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Booking)