import React, {Component} from "react";

import ProductService from "../services/product.service";
import QuoteService from "../services/quote.service";
import {withTranslation} from "react-i18next";
import UserService from "../services/user.service";
import LoaderComponent from "./loader.component";
import '../styles/tarif.scss';
import MetaTags from "react-meta-tags";

class Tarif extends Component {
    product

    constructor(props) {
        super(props);
        this.chooseProduct = this.chooseProduct.bind(this);
        this.state = {
            successful: false,
            message: "",
            type: this.props.match.params.type,
            products: [],
            loading: true,
            carrouselButtons: "",
            carrouselItems: "",
            groupProducts: [],
            individualProducts: [],
            index: 1,
        };
        this.product = 0;
    }

    async componentDidMount() {
        document.title = 'Nos tarifs - talkeasy.fr'
        var products = (await ProductService.getAllData()).data;
        var groupProducts = []
        var individualProducts = []

        products.forEach((product, value) => {
            if (product.is_group === 1) {
                groupProducts.push(product)
            } else {
                individualProducts.push(product)
            }
        })
        this.setState({
            individualProducts: individualProducts,
            groupProducts: groupProducts,
            products: products,
            loading: false
        })
    }

    renderIndividualButtons() {
        return (
            this.state.individualProducts.map((product, index) =>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={index}
                        className={index === 0 ? "active" : null}
                        aria-current="true" aria-label={"Slide " + index}></button>
            ))
    }

    renderIndividualSlides() {
        return (
            this.state.individualProducts.map((product, index) =>
                <div className={index === 0 ? "carousel-item product-item active" : "product-item carousel-item"}>
                    <>
                        <div className="col" key={product.id}>
                            <div
                                className={product.on_sale === '1' ? "card mb-4 rounded-3 shadow-sm border-primary card-product"
                                    : "card mb-4 rounded-3 shadow-sm card-product"}>
                                <div
                                    className={product.on_sale === '1' ? "card-header py-3 text-white bg-primary border-primary"
                                        : "card-header py-3"}>
                                    <h4 className="my-0 fw-normal">{product.name}</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">{product.price_hourly}€<small
                                        className="text-muted fw-light">/heure</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4">
                                        {this.renderDescription(product)}
                                    </ul>
                                    <button type="button" onClick={() => {
                                        this.chooseProduct(product.id)
                                    }}
                                            className={product.on_sale === '1' ? "w-100 btn btn-lg btn-primary" :
                                                "w-100 btn btn-lg btn-outline-primary"}>Choisir
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            ))
    }

    renderGroupButtons() {
        return (
            this.state.groupProducts.map((product, index) =>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={index}
                        className={index === 0 ? "active" : null}
                        aria-current="true" aria-label={"Slide " + index}></button>
            ))
    }

    renderGroupSlides() {
        return (
            this.state.groupProducts.map((product, index) =>
                <div className={index === 0 ? "carousel-item active product-item" : "carousel-item product-item"}>
                    <>
                        <div className="col" key={product.id}>
                            <div
                                className={product.on_sale === '1' ? "card mb-4 rounded-3 shadow-sm border-primary card-product"
                                    : "card mb-4 rounded-3 shadow-sm card-product"}>
                                <div
                                    className={product.on_sale === '1' ? "card-header py-3 text-white bg-primary border-primary"
                                        : "card-header py-3"}>
                                    <h4 className="my-0 fw-normal">{product.name}</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">{product.price_hourly}€<small
                                        className="text-muted fw-light">/heure</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4">
                                        {this.renderDescription(product)}
                                    </ul>
                                    <button type="button" onClick={() => {
                                        this.chooseProduct(product.id)
                                    }}
                                            className={product.on_sale === '1' ? "w-100 btn btn-lg btn-primary" :
                                                "w-100 btn btn-lg btn-outline-primary"}>Choisir
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            ))
    }

    renderDescription(product) {
        try {
            return (
                JSON.parse(product.description).map((productDesc) =>
                    <li> {productDesc} </li>
                ))
        } catch (e) {
            return product.description
        }
    }

    async chooseProduct(productId) {
        UserService.checkConnected();
        let products = []
        products.push({"id": productId, "additional_information": "", "qty": 1})
        var chooseProduct = await QuoteService.create(products);
        if (chooseProduct.status === 200) {
            this.props.history.push("/checkout");
        }
    }


    render() {
        return (
            <>
                <MetaTags>
                    <title>Nos tarifs - talkeasy.fr</title>
                    <meta name="description" content="talkeasy.fr - Les tarifs en ligne, les packs de 5, 10, 20 ou 30 heures en cours individuels ou
                    groupés sont disponibles à l'achat. Réservez vos cours en ligne en quelques cliques."/>
                </MetaTags>
                <LoaderComponent
                    show={this.state.loading}/>
                <div className="container py-3">
                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="display-4 fw-normal">Les tarifs</h1>
                        <p className="fs-5 text-muted">
                            Choissisez la formule à votre besoin.
                            <br/>
                            Une fois le pack acheté vous pouvez réserver votre cours
                        </p>
                    </div>
                    <main>
                        <hr/>
                        <div className="row justify-content-center">
                            <div className="col product-col align-content-center text-center justify-content-center">
                                <h3 className="display-7 fw-normal text-center">Cours Individuels</h3>
                                <div id="carouselIndividualIndicators" className="carousel carousel-dark slide product-carrousel" data-bs-ride="carousel">
                                    <div className="carousel-indicators ">
                                        {this.renderIndividualButtons()}
                                    </div>
                                    <div className="carousel-inner caroussel-product">
                                        {this.renderIndividualSlides()}
                                    </div>
                                    <button className="carousel-control-prev" type="button"
                                            data-bs-target="#carouselIndividualIndicators" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button"
                                            data-bs-target="#carouselIndividualIndicators" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col product-col justify-content-center">
                                <h3 className="display-7 fw-normal text-center">Cours en groupe</h3>
                                <div id="carouselGroupIndicators" className="carousel carousel-dark slide product-carrousel" data-bs-ride="carousel">
                                    <div className="carousel-indicators">
                                        {this.renderGroupButtons()}
                                    </div>
                                    <div className="carousel-inner caroussel-product">
                                        {this.renderGroupSlides()}
                                    </div>
                                    <button className="carousel-control-prev" type="button"
                                            data-bs-target="#carouselGroupIndicators" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button"
                                            data-bs-target="#carouselGroupIndicators" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

export default withTranslation()(Tarif)