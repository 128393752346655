import axios from "axios";
import Addr from "../apiAddr"


class AuthService {
  login(email, password) {
    localStorage.removeItem("user");
    return axios
      .post(Addr.apiAddress() + "/api/auth/signin", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, first_name, last_name, date_birthday, phone, level, sub_level) {
    return axios.post(Addr.apiAddress() + "/api/auth/signup", {
      username : username,
      email : email,
      password : password,
      first_name : first_name,
      last_name: last_name,
      date_birthday: date_birthday,
      phone: phone,
      level: level,
      sub_level : sub_level
    });
  }

  getCurrentUser() {
    if (JSON.parse(localStorage.getItem('user')) === null){
      return false
    }
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
