import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";
import AuthService from "./auth.service"


class UserService {


    getPublicContent() {
        return axios.get(Addr.apiAddress() + '/api/user/all');
    }

    async checkConnected() {

        try {
            await axios.get(Addr.apiAddress() + '/api/user/check', {headers: authHeader()});
            return true;
        } catch (AxiosResponse) {
            localStorage.removeItem("user");
            window.location.href = window.location.protocol + "//" + window.location.hostname + "/login";
            return false;
        }
    }

    async isConnected() {
        try {
            await axios.get(Addr.apiAddress() + '/api/user/check', {headers: authHeader()});
            return true;
        } catch (AxiosResponse) {
            return false;
        }
    }

    async getRoles() {
        return axios.get(Addr.apiAddress() + '/api/user/roles', {headers: authHeader()});
    }

    isManager() {
        return AuthService.getCurrentUser().roles.includes("ROLE_MANAGER");
    }

    getUserBoard() {
        return axios.get(Addr.apiAddress() + '/api/test/user', {headers: authHeader()});
    }

    getModeratorBoard() {
        return axios.get(Addr.apiAddress() + '/api/test/mod', {headers: authHeader()});
    }

    getAdminBoard() {
        return axios.get(Addr.apiAddress() + '/api/test/admin', {headers: authHeader()});
    }

    uploadPhoto(file, onUploadProgress) {
        let formData = new FormData();
        formData.append("file", file);

        localStorage.setItem("avatar", file.name);


        return axios.post(Addr.apiAddress() + '/api/user/photo/upload', formData, {
                "Content-Type": "multipart/form-data",
                headers: authHeader()
            },
            onUploadProgress
        );


    }

    getFiles() {
        return axios.get(Addr.apiAddress() + '/api/user/photo');
    }


    getPhoto(name) {
        return axios.get(Addr.apiAddress() + '/api/user/photo/getByName/' + name);
    }

    getProfile(id) {
        return axios.post(Addr.apiAddress() + '/api/user/getProfile', {id: id}, {headers: authHeader()});
    }

    async getMyProfile() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user !== null) {
            if (user.profile !== undefined) {
                return user.profile
            }
            user.profile = await axios.post(Addr.apiAddress() + '/api/user/getMyProfile', {}, {headers: authHeader()})
            localStorage.removeItem('user')
            localStorage.setItem('user', JSON.stringify(user))
            return user.profile;
        }
        return null;
    }

    async getRefreshMyProfile() {

        let user = JSON.parse(localStorage.getItem('user'));
        if (user !== null) {
            if (user.profile !== undefined) {
                delete user.profile
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(user))
            }
        }
        return await this.getMyProfile();
    }

    getAllUsers() {
        return axios.post(Addr.apiAddress() + '/api/user/getAllUsers', {}, {headers: authHeader()});
    }

    getAllUsersByQuery(query){
        return axios.post(Addr.apiAddress() + '/api/user/getAllUsersByQuery', {query:query}, {headers: authHeader()});
    }

    resetPassword(userId){
        return axios.post(Addr.apiAddress() + '/api/user/resetPassword', {id:userId}, {headers: authHeader()});
    }

    create(data) {
        return axios.post(Addr.apiAddress() + "/api/user/create", {
                username: data.username,
                email: data.email,
                password: data.password,
                first_name: data.first_name,
                last_name: data.last_name,
                date_birthday: data.date_birthday,
                phone: data.phone,
                photo: data.photo,
                level: data.level,
                sub_level: data.sub_level,
                language_level: data.language_level,
            },
            {headers: authHeader()});
    }

    getAllData() {
        return axios.post(Addr.apiAddress() + '/api/user/getAllUsers', {}, {headers: authHeader()});
    }

    setDataById(id, data) {
        return axios.post(Addr.apiAddress() + "/api/admin/user/modify", {
                id: id,
                username: data.username,
                email: data.email,
                password: data.password,
                first_name: data.first_name,
                last_name: data.last_name,
                date_birthday: data.date_birthday,
                phone: data.phone,
                photo: data.photo,
                level: data.level,
                sub_level: data.sub_level,
                language_level: data.language_level,
                lessons_balance: data.lessons_balance,
                group_lessons_balance: data.group_lessons_balance,
            },
            {headers: authHeader()});
    }

    async modify(username, email, oldPassword, newPassword, first_name, last_name, date_birthday, phone) {
        return axios.post(Addr.apiAddress() + "/api/user/modify", {
                username: username,
                email: email,
                oldPassword: oldPassword,
                newPassword: newPassword,
                first_name: first_name,
                last_name: last_name,
                date_birthday: date_birthday,
                phone: phone
            },
            {headers: authHeader()});
    }

    getDataById(id) {
        return axios.post(Addr.apiAddress() + "/api/user/id", {
                id: id
            },
            {headers: authHeader()});
    }

    delete(id) {
        return axios.post(Addr.apiAddress() + "/api/user/delete", {
                id: id
            },
            {headers: authHeader()});
    }

    addHourIndividual(id) {
        return axios.post(Addr.apiAddress() + "/api/user/addHourIndividual", {
                id: id
            },
            {headers: authHeader()});
    }

    addHourGroup(id) {
        return axios.post(Addr.apiAddress() + "/api/user/addHourGroup", {
                id: id
            },
            {headers: authHeader()});
    }



}


export default new UserService();
