import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ListComponent from './list.component'
class IndexGroupComponent extends Component {

    componentDidMount() {
        document.title = 'Cours en groupe - talkeasy.fr'
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="px-4 pt-5 my-5 text-center border-bottom">
                    <h1 className="display-4 fw-bold">Cours en groupe</h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Profitez des cours en petites groupes pour mieux pratiquer les langues, tout pour un prix
                            <b> avantageux!</b>
                        </p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                            <Link className="btn btn-primary btn-lg px-4 me-sm-3" to={"/tarif"}>
                                {t('Voir les packs')}
                            </Link>
                        </div>
                    </div>


                </div>
                <ListComponent
                    history={this.props.history}
                />

            </>
        )
    }
}

export default withTranslation()(IndexGroupComponent);