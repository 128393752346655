import React, {Component} from "react";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";
import '../styles/footer.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

class NavbarBottom extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                showAdminBoard: user.roles.includes("ROLE_ADMIN"),
            });
        }

    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }


    render() {

        return (
            <>
                <a href={"tel:+33627356295"}>
                    <footer  className="navbar navbar-expand navbar-dark bg-dark fixed-bottom navbar-footer footer-mobile">
                        <div className="container-fluid footer-container">
                            <div className='footerIcon'>
                                <i className="bi bi-telephone"></i>
                                <small className="text-muted">
                                    &nbsp;&nbsp;
                                    Des questions ? &nbsp; Téléphonez nous !
                                </small>
                                <br/>
                            </div>
                        </div>
                    </footer>
                </a>
            </>

        );
    }
}

export default NavbarBottom;





