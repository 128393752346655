import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";

class ProductService {




    async callback(first_name, last_name, email, phone, userMessage){
        return axios.post(Addr.apiAddress() + "/api/callback", {
                first_name : first_name,
                last_name: last_name,
                email: email,
                phone: phone,
                userMessage: userMessage,
            },
            {headers: authHeader()});
    }



}
export default new ProductService();
